import { Opportunity } from '../models/Opportunity';
import { apiService } from './apiService';

const OPPORTUNITIES_ENDPOINT = '/api/data/opportunities';

export async function getOpportunity(id: number): Promise<Opportunity> {
  return await apiService<Opportunity>(`${OPPORTUNITIES_ENDPOINT}/${id}`);
}

export async function updateOpportunityStage(
  opportunityId: number,
  stageId: number
): Promise<void> {
  apiService<Opportunity>(`${OPPORTUNITIES_ENDPOINT}/${opportunityId}/stage`, {
    method: 'PATCH',
    body: JSON.stringify({ stageId }),
  });
}

export const opportunityService = {
  getOpportunity,
  updateOpportunityStage,
};
