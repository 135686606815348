import React, { useState } from 'react';
import { Note, NOTE_TYPES } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';

type NoteType = typeof NOTE_TYPES[number];

interface NoteEditorProps {
  note?: Note;
  content: string;
  type: NoteType;
  onContentChange: (content: string) => void;
  onTypeChange: (type: NoteType) => void;
  submitButtonText?: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const NoteEditor: React.FC<NoteEditorProps> = ({
  note,
  content,
  type,
  onContentChange,
  onTypeChange,
  submitButtonText = note ? 'Save Note' : 'Create Note',
  onSubmit,
  onCancel
}) => {
  const [addToCalendar, setAddToCalendar] = useState(false);
  const originalContent = note?.content || '';

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const handleCancel = () => {
    onContentChange(originalContent);
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit} className="flex-1 flex flex-col">
      <div className="flex-1 p-6 overflow-y-auto space-y-6">
        {/* Note Content */}
        <div>
          <label className={`block text-xs font-medium ${THEME_CLASSES.text.secondary} mb-2`}>
            Note
          </label>
          <textarea
            required
            value={content}
            onChange={(e) => onContentChange(e.target.value)}
            className={`block w-full ${THEME_CLASSES.input.textarea} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} ${THEME_CLASSES.input.base}`}
            rows={12}
            placeholder="Enter note content"
          />
        </div>

        {/* Note Type */}
        <div>
          <label className={`block text-xs font-medium ${THEME_CLASSES.text.secondary} mb-2`}>
            Type
          </label>
          <select
            value={type}
            onChange={(e) => onTypeChange(e.target.value as NoteType)}
            className={`block w-full ${THEME_CLASSES.input.field} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.base}`}
          >
            {NOTE_TYPES.filter(noteType => noteType !== 'All').map(noteType => (
              <option key={noteType} value={noteType}>{noteType}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Action Buttons */}
      <div className={`flex items-center justify-between gap-4 py-4 px-6 mt-4 ${THEME_CLASSES.border} border-t`}>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="addToCalendar"
            checked={addToCalendar}
            onChange={(e) => setAddToCalendar(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="addToCalendar" className={`text-xs font-medium ${THEME_CLASSES.text.secondary}`}>
            Add to Google Calendar
          </label>
        </div>
        <div className="flex gap-4">
          <button
            type="button"
            onClick={handleCancel}
            className={`inline-flex items-center px-4 py-2 rounded-md text-xs font-medium ${THEME_CLASSES.button.secondary}`}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium ${THEME_CLASSES.button.primary}`}
          >
            {submitButtonText}
          </button>
        </div>
      </div>
    </form>
  );
};

export default NoteEditor;
