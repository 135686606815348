import { Note } from '../models/Note';
import { apiService } from './apiService';

const BASE_URL = '/api/data';
const NOTES_ENDPOINT = '/api/data/notes';

type noteType = 'contact' | 'opportunity' | 'account' | 'lead';

export async function getNotes(type: noteType): Promise<Note[]> {
  /* return apiService<Note[]>(`${BASE_URL}/notes/${type}`); */
  return [];
}

export async function getNotesByType(type: noteType, id: number): Promise<Note[]> {
  switch (type) {
    case 'contact':
      return getNotesByContactId(id);
    case 'opportunity':
      return getNotesByOpportunityId(id);
    case 'account':
      return getNotesByAccountId(id);
    case 'lead':
      return getNotesByLeadId(id);
    default:
      return [];
  }
}

export async function getAllNoteCount(): Promise<number> {
  const result = await apiService<{count: number}>(`${NOTES_ENDPOINT}/count`);
  return result.count;
}

export async function getNotesByContactId(contactId: number): Promise<Note[]> {
  return apiService<Note[]>(`${BASE_URL}/contacts/${contactId}/notes`);
}

export async function getNotesByOpportunityId(opportunityId: number): Promise<Note[]> {
  return apiService<Note[]>(`${BASE_URL}/opportunities/${opportunityId}/notes`);
}

export async function getNotesByAccountId(accountId: number): Promise<Note[]> {
  return apiService<Note[]>(`${BASE_URL}/accounts/${accountId}/notes`);
}

export async function getNotesByLeadId(leadId: number): Promise<Note[]> {
  return apiService<Note[]>(`${BASE_URL}/leads/${leadId}/notes`);
}

export async function createNote(data: any): Promise<Note> {
  return apiService<Note>(`${BASE_URL}/notes`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export async function updateNote(note: Note): Promise<Note> {
  return apiService<Note>(`${BASE_URL}/notes/${note.noteId}`, {
    method: 'PUT',
    body: JSON.stringify(note)
  });
}

export async function updateNoteStatus(noteId: number, statusId: number): Promise<void> {
  await apiService<void>(`${BASE_URL}/notes/${noteId}/status`, {
    method: 'PATCH',
    body: JSON.stringify({ status: statusId })
  });
}

export async function updateNoteDates(
  noteId: number,
  startDate: Date | null,
  dueDate: Date | null
): Promise<void> {
  await apiService<void>(`${BASE_URL}/notes/${noteId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      startDate: startDate?.toISOString(),
      dueDate: dueDate?.toISOString()
    })
  });
}

export const noteService = {
  getAllNoteCount,
  getNotesByContactId,
  getNotesByOpportunityId,
  getNotesByAccountId,
  getNotesByLeadId,
  getNotesByType,
  getNotes,
  createNote,
  updateNote,
  updateNoteStatus,
  updateNoteDates
};
