export const highlightText = (text: string | null, keywords: string[]): (string | JSX.Element)[] => {
  if (!text) return [];
  if (!keywords.length) return [text];

  const regex = new RegExp(`(${keywords.map(k => k.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'gi');
  return text.split(regex).map((part, i) =>
    keywords.includes(part.toLowerCase()) ? (
      <span key={i} className="bg-yellow-200 dark:bg-yellow-600 leading-none px-0">{part}</span>
    ) : (
      part
    )
  );
};
