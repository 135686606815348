import { useState, useEffect } from 'react';
import { Pipeline, PipelineStage } from '../../models/Pipeline';
import { Opportunity } from '../../models/Opportunity';
import { pipelineService } from '../../services/pipelineService';
import OpportunityForm from './OpportunityForm';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface OpportunityWizardProps {
  onClose: () => void;
  initialStageId?: number;
  opportunity?: Opportunity;
}

const OpportunityWizard: React.FC<OpportunityWizardProps> = ({ opportunity, onClose, initialStageId }) => {
  const [step, setStep] = useState(initialStageId ? 2 : 1);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [stages, setStages] = useState<PipelineStage[]>([]);
  const [stageId, setStageId] = useState<number | null>(initialStageId || null);
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline | null>(null);
  const [opportunityData, setOpportunityData] = useState<Opportunity>({
    id: 0,
    title: '',
    value: 0,
    description: '',
    status: '',
    potentialAcquirers: '',
    markets: '',
    province: '',
    salesType: '',
    numberOfAgents: 0,
    probability: 0,
    lastOutreach: new Date(),
    nextOutreach: new Date(),
    origination: '',
    targetCloseDate: new Date(),
    disclosureDate: new Date(),
    streetAddress: '',
    city: '',
    postalCode: '',
    stageId: initialStageId || 0,
    stageName: '',
    pipelineId: 0,
    contactId: 0,
    owners: '',
    createDate: new Date(),
    createdAt: new Date(),
    modifiedDate: new Date(),
    createdBy: '',
    modifiedBy: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (step === 1 && pipelines.length === 0) {
      const loadPipelines = async () => {
        try {
          const data = await pipelineService.getPipelines();
          setPipelines(data);
        } catch (err) {
          console.error('Failed to load pipelines', err);
        }
      };
      loadPipelines();
    }
  }, [step, pipelines]);

  const handlePipelineSelect = async (pipelineId: number) => {
    const pipeline = pipelines.find(p => p.id === pipelineId);
    if (!pipeline) return;
    
    setSelectedPipeline(pipeline);
    try {
      const stages = await pipelineService.getPipelineStages(pipelineId);
      setStages(stages);
      if (stages.length > 0) {
        setStageId(stages[0].id);
      }
    } catch (err) {
      console.error('Failed to load pipeline stages', err);
    }
  };

  const handleCreateOpportunity = async () => {
    if (!selectedPipeline) {
      setError('Please select a pipeline');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await pipelineService.createOpportunity(Number(selectedPipeline.id), {
        ...opportunityData,
        status: initialStageId?.toString() || selectedPipeline.stages[0].id.toString()
      });
      setSuccess(true);
      handleNext();
    } catch (err) {
      setError('Failed to create opportunity');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-transparent">
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
        <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
            {opportunity ? 'Edit' : 'Create'} Opportunity
          </h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
            aria-label="Close"
          >
            ×
          </button>
        </div>

        {step === 1 && (
          <div className="space-y-4">
            <div className="mb-6">
              <label className={`block text-sm font-medium mb-1 ${THEME_CLASSES.text.primary}`}>
                Select Pipeline
              </label>
              <select
                value={selectedPipeline?.id || ''}
                onChange={(e) => {
                  const pipelineId = Number(e.target.value);
                  handlePipelineSelect(pipelineId);
                }}
                className={`w-full px-3 py-2 border rounded-md text-sm ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
                aria-label="Select a pipeline"
              >
                <option value="">Select a pipeline</option>
                {pipelines.map(pipeline => (
                  <option key={pipeline.id} value={pipeline.id}>
                    {pipeline.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                disabled={!selectedPipeline}
                className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.primary} disabled:opacity-50`}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-4">
            <OpportunityForm
              initialData={opportunity || opportunityData}
              onSubmit={(data) => {
                setOpportunityData(data);
                handleCreateOpportunity();
              }}
              onCancel={onClose}
            />
            <div className="flex justify-end">
              {!initialStageId ? (
                <>
                  <button
                    onClick={handleBack}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
                  >
                    Back
                  </button>
                  <div className="flex-1" />
                </>
              ) : null}
              <div className="flex space-x-2">
                <button
                  onClick={onClose}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setOpportunityData(opportunityData);
                    handleCreateOpportunity();
                  }}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.primary}`}
                >
                  Save Opportunity
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="space-y-4">
            {loading && (
              <div className="flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100" />
              </div>
            )}

            {error && (
              <div className={`p-4 rounded-md ${THEME_CLASSES.status.error}`}>
                <p className="text-sm">{error}</p>
              </div>
            )}

            {success && (
              <div className="space-y-4">
                <div className={`p-4 rounded-md ${THEME_CLASSES.status.success}`}>
                  <p className="text-sm">
                    Opportunity created successfully!
                  </p>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={onClose}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      // TODO: Implement navigation to pipeline screen
                      onClose();
                    }}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.primary}`}
                  >
                    Go to Pipeline
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunityWizard;
