import { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { Contact } from '../../models/Contact';
import { contactService } from '../../services/contactService';
import useDebounce from '../../hooks/useDebounce';
import { getAvatarConfig } from '../../utils/avatarUtils';
import { useEventBus } from '../../context/EventBusContext';

import React from 'react';

interface HighlightProps {
  text: string;
  searchTerm: string;
}

const HighlightedText: React.FC<HighlightProps> = ({ text, searchTerm }) => {
  if (!searchTerm) return <>{text}</>;
  
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
  
  return (
    <>
      {parts.map((part, index) => 
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="bg-yellow-200 dark:bg-yellow-600 dark:text-white rounded px-0.5">
            {part}
          </span>
        ) : (
          <React.Fragment key={index}>{part}</React.Fragment>
        )
      )}
    </>
  );
};

interface TypeaheadSearchProps {
  onSelect: (contact: Contact) => void;
  placeholder?: string;
  className?: string;
}

const TypeaheadSearch: React.FC<TypeaheadSearchProps> = ({ 
  onSelect, 
  placeholder = 'Search contacts...',
  className = ''
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<Contact[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Search handler
  const performSearch = async (query: string) => {
    if (query.length < 2) {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const results = await contactService.searchContacts(query);
      setSearchResults(results);
      setShowDropdown(results.length > 0);
    } catch (err) {
      setError('Failed to load search results');
      console.error('Search error:', err);
      setSearchResults([]);
      setShowDropdown(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change
  const handleInputChange = (value: string) => {
    setSearchValue(value);
    setActiveIndex(-1);
  };

  // Handle result selection
  const handleResultSelect = (contact: Contact) => {
    onSelect(contact);
    setSearchValue('');
    setSearchResults([]);
    setShowDropdown(false);
    inputRef.current?.blur();
  };

  // Keyboard navigation
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(prev => Math.min(prev + 1, searchResults.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(prev => Math.max(prev - 1, -1));
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      handleResultSelect(searchResults[activeIndex]);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (searchContainerRef.current && 
          !searchContainerRef.current.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Subscribe to contact:search event
  const { subscribe, unsubscribe } = useEventBus();
  
  useEffect(() => {
    const handleSearch = (searchTerm: string) => {
      handleInputChange(searchTerm);
    };
    
    subscribe('contacts:search', handleSearch);
    return () => unsubscribe('contacts:search', handleSearch);
  }, [subscribe, unsubscribe]);

  // Perform search when debounced value changes  
  useEffect(() => {
    performSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <div ref={searchContainerRef} className={`relative ${className}`}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={searchValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={`w-full pl-10 pr-10 py-2 rounded-md text-sm focus:outline-none focus:ring-1 ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {isLoading ? (
            <div className="animate-spin h-5 w-5 text-gray-400 border-2 border-gray-400 border-t-transparent rounded-full" />
          ) : (
            <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          )}
        </div>
      </div>

      {showDropdown && (
        <div className={`absolute z-50 w-full mt-2 rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 ${THEME_CLASSES.input.background} ${THEME_CLASSES.border}`}>
          <ul className="py-2">
            {searchResults.map((contact, index) => {
              const { initials, bgColor, textColor } = getAvatarConfig(contact);
              const isActive = index === activeIndex;
              
              return (
                <li
                  key={contact.id}
                  onClick={() => handleResultSelect(contact)}
                  className={`px-4 py-4 cursor-pointer ${
                    isActive ? THEME_CLASSES.hover.background : ''
                  } ${THEME_CLASSES.hover.background} hover:bg-opacity-90 transition-all duration-150 flex items-center`}
                >
                  <div className={`w-8 h-8 rounded-full ${bgColor} flex items-center justify-center flex-shrink-0`}>
                    <span className={`text-sm font-medium ${textColor}`}>{initials}</span>
                  </div>
                  <div className="ml-3">
                    <div className={`text-base font-semibold ${THEME_CLASSES.input.text}`}>
                      <HighlightedText text={`${contact.firstName} ${contact.lastName}`} searchTerm={searchValue} />
                    </div>
                    <div className={`text-xs ${THEME_CLASSES.input.text} opacity-75`}>
                      {contact.companyName || contact.company || 'Unknown Brokerage'}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {error && (
        <div className={`text-sm mt-1 ${THEME_CLASSES.text.secondary}`}>{error}</div>
      )}
    </div>
  );
};

export default TypeaheadSearch;
