import { FC } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';

const Events: FC = () => {
  return (
    <div className="p-6">
      <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} my-auto`}>Events</h1>
      <div className="mt-4">
        {/* Events content will go here */}
      </div>
    </div>
  );
};

export default Events;
