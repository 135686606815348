import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { THEME_CLASSES } from '../constants/themeConstants';
import { accountService } from '../services/accountService';
import { noteService } from '../services/noteService';
import { Account } from '../models/Account';
import { Note } from '../models/Note';
import Spinner from '../components/common/Spinner';
import AccountDetails from '../components/account/AccountDetails';

import NotesTable from '../components/note/NotesTable';
import NotesBoard from '../components/note/NotesBoard';
import NotesTimeline from '../components/note/NotesGantt';

const AccountPage: FC = () => {
  const { id } = useParams();
  const [account, setAccount] = useState<Account | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) return;
        const [accountData, notesData] = await Promise.all([
          accountService.getAccount(parseInt(id)),
          noteService.getNotesByContactId(parseInt(id)).then(notes => notes || [])
        ]);
        setAccount(accountData);
        setNotes(notesData);
        setError(null);
      } catch (err) {
        setError('Failed to load account details');
        console.error('Error fetching account:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div className="text-red-500">{error}</div>
        </div>
      </div>
    );
  }

  if (!account) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div>Account not found</div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col p-6">
      <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} mb-6`}>
        {account.name}
      </h1>

      <div className={`${THEME_CLASSES.background} border-b ${THEME_CLASSES.border} mb-2`}>
        <div className="px-4">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button
              onClick={() => setActiveTab('overview')}
              className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'overview'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('timeline')}
              className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'timeline'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                }`}
            >
              Timeline
            </button>
            <button
              onClick={() => setActiveTab('notes')}
              className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'notes'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                }`}
            >
              Notes
            </button>
            <button
              onClick={() => setActiveTab('board')}
              className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'board'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                }`}
            >
              Board
            </button>
            <button
              onClick={() => setActiveTab('gantt')}
              className={`rounded-full py-1 px-4 text-sm font-medium ${activeTab === 'gantt'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                }`}
            >
              Gantt
            </button>
          </nav>
          <div className={`h-px w-full ${THEME_CLASSES.border} mt-4`}></div>
        </div>
      </div>

      {activeTab === 'overview' && (
        <div className="grid grid-cols-1 gap-6">
          <AccountDetails account={account} />
        </div>

      )}

      {activeTab === 'timeline' && (

        <div className="grid grid-cols-1 gap-6">
          Timeine View (Coming Soon)
        </div>
      )}

      {activeTab === 'notes' && (
        <div className="grid grid-cols-1 gap-6">
          <NotesTable type="account" id={parseInt(id!)} />
        </div>
      )}

      {activeTab === 'board' && (
        <div className="grid grid-cols-1 gap-6">
          <NotesBoard type="account" id={parseInt(id!)} />
        </div>
      )}

      {activeTab === 'gantt' && (
        <div className="grid grid-cols-1 gap-6">
          <NotesTimeline type="account" id={parseInt(id!)} />
        </div>
      )}
    </div>
  );
};

export default AccountPage;
