import { Contact } from '../models/Contact';

interface AvatarConfig {
  initials: string;
  bgColor: string;
  textColor: string;
}

export const getContactPhotoUrl = (contact: Contact): string => {
  return contact.rlpPersonId 
    ? `//admin.rlpnetwork.com/api/users/photo/${contact.rlpPersonId}`
    : '//rlpnetwork.com/wp-content/themes/rlp/img/person.png';
};

export const getAvatarConfig = (contact: Contact): AvatarConfig => {
  const initials = `${contact.firstName?.[0] || ''}${contact.lastName?.[0] || ''}`;
  const isActive = contact.employeeStatus === 'Active';

  return {
    initials,
    bgColor: isActive ? 'bg-red-500' : 'bg-gray-200',
    textColor: isActive ? 'text-white' : 'text-gray-900'
  };
};
