import { getAuthHeaders, handleResponse } from './authService';
import { convertKeysToCamelCase } from '../utils/camelCase';

export async function apiService<T>(input: RequestInfo | URL, init?: RequestInit): Promise<T> {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(input, {
      ...init,
      headers: {
        ...headers,
        ...init?.headers,
      },
    });

    const json = await handleResponse(response);
    const camelCaseJson = convertKeysToCamelCase(json) as T;
    return camelCaseJson;
  } catch (error) {
    // console.error('API request failed:', error);
    // throw error;
    return {} as T;
  }
}
