import { FC } from 'react';
import { Contact } from '../../models/Contact';

interface ContactDetailsProps {
  contact: Contact;
}

const ContactDetails: FC<ContactDetailsProps> = ({ contact }) => {
  const formatDate = (dateString: string | undefined) => {
    return dateString ? new Date(dateString).toLocaleDateString() : '-';
  };

  const sections = [
    {
      title: 'Personal Information',
      fields: [
        { label: 'First Name', value: contact.firstName },
        { label: 'Last Name', value: contact.lastName },
        { label: 'Display Name', value: contact.displayName },
        { label: 'Preferred Language', value: contact.preferredLanguage },
        { label: 'Sex', value: contact.sex },
        { label: 'Title', value: contact.title },
      ]
    },
    {
      title: 'Contact Information',
      fields: [
        { label: 'Email Address', value: contact.email },
        { label: 'Communications Email', value: contact.communicationsEmail },
        { label: 'Person ID', value: contact.rlpPersonId },
      ]
    },
    {
      title: 'Employment Information',
      fields: [
        { label: 'Company Name', value: contact.companyName },
        { label: 'Employee Type', value: contact.employeeType },
        { label: 'Start Date', value: formatDate(contact.startDate) },
        { label: 'Status', value: contact.employeeStatus },
      ]
    },
    {
      title: 'Brokerage Information',
      fields: [
        { label: 'Protection Royale', value: contact.protectionRoyale },
        { label: 'Commercial', value: contact.commercial },
        { label: 'Affiliated Broker', value: contact.affiliatedBroker },
        { label: 'Designation', value: contact.designation },
        { label: 'Recruitment Source', value: contact.recruitmentSource },
        { label: 'Previous Affiliation', value: contact.previousAffiliation },
        { label: 'Future Affiliation', value: contact.futureAffiliation },
      ]
    }
  ];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 space-y-8">
      {sections.map(section => (
        <div key={section.title}>
          <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            {section.title}
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {section.fields.map(field => (
              <div
                key={field.label}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 p-4 rounded-lg transition-colors"
              >
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  {field.label}
                </div>
                <div className="text-sm font-semibold text-gray-900 dark:text-gray-100">
                  {typeof field.value === 'boolean' ? (field.value ? 'Yes' : 'No') : field.value || '-'}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactDetails;
