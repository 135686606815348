import { useState, useEffect, useMemo } from 'react';
import { newsService } from '../../services/newsService';
import type { NewsItem } from '../../models/NewsItem';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { getAvatarConfig } from '../../utils/avatarUtils';
import { highlightText } from '../../utils/highlightUtils';

export default function News() {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem('news-search') || '';
  });

  useEffect(() => {
    localStorage.setItem('news-search', searchQuery);
  }, [searchQuery]);
  
  const keywords = useMemo(() => 
    searchQuery
      .toLowerCase()
      .split(' ')
      .map(k => k.trim())
      .filter(k => k.length > 0),
    [searchQuery]
  );

  const getCalendarDates = () => {
    const startDate = new Date(currentMonth);
    startDate.setDate(1);
    const startDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - startDay);

    const dates = [];
    for (let i = 0; i < 35; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await newsService.getAllNews();
        setNews(data);
      } catch (error) {
        console.error('Failed to fetch news:', error);
      }
    };

    void fetchNews();
  }, []);

  const dates = getCalendarDates();

  return (
    <div className={`${THEME_CLASSES.background} p-6 rounded-lg shadow h-full flex flex-col lg:flex-row gap-8`}>
      {/* Calendar */}
      <div className="hidden lg:block lg:w-1/3">
        {/* Calendar Header */}
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => {
              const newDate = new Date(currentMonth);
              newDate.setMonth(newDate.getMonth() - 1);
              setCurrentMonth(newDate);
            }}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded text-gray-600 dark:text-gray-300"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </span>
          <button
            onClick={() => {
              const newDate = new Date(currentMonth);
              newDate.setMonth(newDate.getMonth() + 1);
              setCurrentMonth(newDate);
            }}
            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded text-gray-600 dark:text-gray-300"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Calendar Grid */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 border dark:border-gray-700 min-h-[400px]">
          <div className="grid grid-cols-7 gap-0.5 mb-4">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className={`text-center text-base font-bold py-2 ${THEME_CLASSES.text.primary}`}>
                {day}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-2">
            {dates.map((date, index) => {
              const dayNews = news.filter(item =>
                new Date(item.createdAt).toDateString() === date.toDateString()
              );

              const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
              const isToday = date.toDateString() === new Date().toDateString();

              return (
                <div key={index} className="h-14">
                  <button
                    onClick={() => {
                      if (selectedDate?.toDateString() === date.toDateString()) {
                        setSelectedDate(null);
                      } else {
                        setSelectedDate(date);
                      }
                    }}
                    className={`w-10 h-10 flex items-center justify-center text-sm rounded-full relative
                      ${isToday ? 'font-bold' : ''}
                      ${isCurrentMonth
                        ? 'text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800'
                        : 'opacity-50'
                      }
                      ${selectedDate?.toDateString() === date.toDateString() 
                        ? 'ring-2 ring-blue-500 rounded-full' 
                        : ''
                      }
                    `}
                  >
                    {date.getDate()}
                    {dayNews.length > 0 && (
                      <span className="absolute bottom-0.5 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 rounded-full bg-blue-500" />
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Feeds */}
      <div className="w-full lg:w-2/3 flex flex-col h-full overflow-hidden">
        {/* Search Header - Fixed */}
        <div className="flex items-center mb-6 bg-inherit">
          <h2 className={`${THEME_CLASSES.text.primary} text-xl font-semibold mr-4`}>Latest News</h2>
          <div className="w-96 relative ml-auto">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search news..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
            />
            {searchQuery && (
              <button
                onClick={() => setSearchQuery('')}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* Scrollable News Content */}
        <div className="overflow-y-auto flex-1">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 pb-6">
            {news
              .filter(item => {
                const dateMatch = !selectedDate || 
                  new Date(item.createdAt).toDateString() === selectedDate.toDateString();
                
                const searchMatch = keywords.length === 0 || 
                  keywords.some(k => 
                    item.content.toLowerCase().includes(k) ||
                    item.createdBy.toLowerCase().includes(k)
                  );
                
                return dateMatch && searchMatch;
              })
              .map((item) => {
                const [firstName, lastName] = item.createdBy.split(' ');
                const contact = { firstName, lastName, employeeStatus: 'Active' };
                const avartarConfig = getAvatarConfig(contact as any);
                return (
                  <div key={item.id} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <div className={`w-8 h-8 rounded-full flex items-center justify-center ${avartarConfig.bgColor} ${avartarConfig.textColor}`}>
                          <span className="text-xs font-medium">
                            {avartarConfig.initials}
                          </span>
                        </div>
                        <div className={`${THEME_CLASSES.text.primary} text-sm font-medium`}>
                          {highlightText(item.createdBy, keywords)}
                        </div>
                      </div>
                      <div className={`${THEME_CLASSES.text.secondary} text-xs`}>
                        {new Date(item.createdAt).toLocaleDateString()}
                      </div>
                    </div>
                    <p className={`${THEME_CLASSES.text.secondary} text-sm mt-1`}>
                      {highlightText(item.content, keywords)}
                    </p>
                    <a
                      href={item.url}
                      rel="noopener noreferrer"
                      className={`${THEME_CLASSES.text.primary} text-xs underline mt-2 block`}
                    >
                      Read more
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
