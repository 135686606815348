import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { contactService } from '../services/contactService';
import { accountService } from '../services/accountService';
import { pipelineService } from '../services/pipelineService';
import { noteService } from '../services/noteService';
import { Account } from '../models/Account';
import { Pipeline } from '../models/Pipeline';
import { THEME_CLASSES } from '../constants/themeConstants';
import SuggestionActionsPanel from '../components/dashboard/SuggestionActionsPanel';
import News from '../components/dashboard/News';

function Dashboard() {
  const navigate = useNavigate();
  const [contactsCount, setContactsCount] = useState<number>(0);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [notesCount, setNotesCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      const [count, accountsData, pipelinesData, notesCount] = await Promise.all([
        contactService.getContactsCount(),
        accountService.getAccounts(),
        pipelineService.getPipelines(),
        noteService.getAllNoteCount()
      ]);

      setContactsCount(count);
      setAccounts(accountsData);
      setPipelines(pipelinesData);
      setNotesCount(notesCount);
    };

    void fetchData();
  }, []);

  const stats = useMemo(() => [
    {
      title: 'Total Accounts',
      value: accounts.length.toLocaleString(),
      change: '+2 from last month',
      icon: (
        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
      )
    },
    {
      title: 'Total Contacts',
      value: contactsCount.toLocaleString(),
      change: '+27 from last month',
      icon: (
        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    },
    {
      title: 'Total Pipeline',
      value: pipelines.length.toString(),
      change: 0,
      icon: (
        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    {
      title: 'Total Notes',
      value: notesCount.toLocaleString(),
      change: '+12 from last month',
      icon: (
        <svg className="w-6 h-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      )
    },
  ], [contactsCount, accounts.length, pipelines, notesCount]);

  return (
    <div className='h-full flex flex-col gap-2 overflow-y-auto'>
      <h1 className={`${THEME_CLASSES.text.primary} text-xl font-bold tracking-tight`}>Dashboard</h1>
      {/* Statistics */}
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 mb-6" aria-label="Statistics">
        {stats.map((stat) => (
          <div
            key={stat.title}
            className={`p-6 ${THEME_CLASSES.input.background} rounded-xl border ${THEME_CLASSES.border} transition-colors duration-200 cursor-pointer hover:shadow-lg`}
            role="figure"
            onClick={() => {
              switch (stat.title) {
                case 'Total Accounts':
                  navigate('/accounts');
                  break;
                case 'Total Contacts':
                  navigate('/contacts');
                  break;
                case 'Total Notes':
                  navigate('/notes');
                  break;
                case 'Total Pipeline':
                  navigate('/pipelines');
                  break;
              }
            }}
          >
            <div className="flex justify-between items-start mb-4">
              <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>{stat.title}</p>
              <span>{stat.icon}</span>
            </div>
            <p className={`text-2xl font-semibold mb-2 ${THEME_CLASSES.text.primary}`}>{stat.value}</p>
            <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>{stat.change}</p>
          </div>
        ))}
      </section>

      {/* main content */}
      <div className="h-[calc(100vh-400px)]">
        <News />
      </div>
      <SuggestionActionsPanel />
    </div>
  );
}

export default Dashboard;
