import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { THEME_CLASSES } from '../constants/themeConstants';
import { pipelineService } from '../services/pipelineService';
import { Pipeline } from '../models/Pipeline';
import PipelineDetails from '../components/pipeline/PipelineDetails';
import Spinner from '../components/common/Spinner';

const Pipelines: FC = () => {
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const navigate = useNavigate();
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlePipelineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const pipeline = pipelines.find(p => p.id === parseInt(event.target.value));
    setSelectedPipeline(pipeline || null);
  };

  useEffect(() => {
    const fetchPipelines = async () => {
      try {
        const data = await pipelineService.getPipelines();
        setPipelines(data);
        if (data.length > 0) {
          setSelectedPipeline(data[0]);
        }
      } catch (err) {
        setError('Failed to load pipelines');
      } finally {
        setLoading(false);
      }
    };

    fetchPipelines();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className='h-full flex flex-col'>
      {/* Header */}
      {loading ? (
        <div className={`p-4 border-b ${THEME_CLASSES.border}`}>
          <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary}`}>Loading pipelines...</h1>
        </div>
      ) : error ? (
        <div className={`p-4 border-b ${THEME_CLASSES.border}`}>
          <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary}`}>{error}</h1>
        </div>
      ) : (
        <div className={`p-4 border-b ${THEME_CLASSES.border} flex items-center gap-4 ${THEME_CLASSES.background}`}>
          <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} my-auto`}>Pipelines</h1>
          <select 
            className={`px-4 py-2 border rounded-md w-64 text-sm text-left ml-auto ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
            onChange={(e) => {
              handlePipelineChange(e);
              navigate(`/pipelines/${e.target.value}`);
            }}
            value={selectedPipeline?.id || ''}
          >
            {pipelines.map((pipeline) => (
              <option key={pipeline.id} value={pipeline.id}>
                {pipeline.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Content */}
      <PipelineDetails pipeline={selectedPipeline} />
    </div>
  );
};

export default Pipelines;
