import { Opportunity } from '../models/Opportunity';
import { Pipeline, PipelineStage } from '../models/Pipeline';
import { apiService } from './apiService';

const PIPELINES_ENDPOINT = '/api/data/pipelines';

export async function getPipelines(): Promise<Pipeline[]> {
  return apiService<Pipeline[]>(PIPELINES_ENDPOINT);
}

export async function getPipeline(id: number): Promise<Pipeline> {
  return apiService<Pipeline>(`${PIPELINES_ENDPOINT}/${id}`);
}

export async function createPipeline(pipeline: Omit<Pipeline, 'ID'>): Promise<Pipeline> {
  return apiService<Pipeline>(PIPELINES_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(pipeline),
  });
}

export async function updatePipeline(id: number, pipeline: Partial<Pipeline>): Promise<Pipeline> {
  return apiService<Pipeline>(`${PIPELINES_ENDPOINT}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(pipeline),
  });
}

export async function deletePipeline(id: number): Promise<void> {
  await apiService<void>(`${PIPELINES_ENDPOINT}/${id}`, {
    method: 'DELETE',
  });
}

export async function getPipelineStages(id: number): Promise<PipelineStage[]> {
  return apiService<PipelineStage[]>(`${PIPELINES_ENDPOINT}/${id}/stages`);
}

export async function getPipelineOpportunities(id: number): Promise<Opportunity[]> {
  return apiService<Opportunity[]>(`${PIPELINES_ENDPOINT}/${id}/opportunities`);
}
export async function createOpportunity(
  pipelineId: number,
  opportunity: Omit<Opportunity, 'id'>
): Promise<Opportunity> {
  return apiService<Opportunity>(`${PIPELINES_ENDPOINT}/${pipelineId}/opportunities`, {
    method: 'POST',
    body: JSON.stringify(opportunity),
  });
}

export const pipelineService = {
  getPipelines,
  getPipeline,
  createPipeline,
  updatePipeline,
  deletePipeline,
  getPipelineStages,
  getPipelineOpportunities,
  createOpportunity,
}
