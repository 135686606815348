import { FC, useState, useEffect, useCallback } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { noteService } from '../../services/noteService';
import { newsService } from '../../services/newsService';
import { trimTitle } from '../../utils/stringUtils';
import { Note, NoteComponentProps, NOTE_TYPES } from '../../models/Note';
import NoteEditor from './NoteEditor';

interface LaneProps {
  title: string;
  status: string;
  notes: Note[];
  editingNoteId: number | null;
  draggingNoteId: number | null;
  hoveredLane: string | null;
  onDragStart: (e: React.DragEvent, noteId: number) => void;
  onDragEnd: () => void;
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent, status: string) => void;
  setEditingNoteId: (id: number | null) => void;
  setHoveredLane: (status: string | null) => void;
  handleSave: (note: Note) => void;
  color: string;
  onAddNote: () => void;
}

interface NoteCardProps {
  note: Note;
  isEditing: boolean;
  onEdit: () => void;
  onDragStart: (e: React.DragEvent, noteId: number) => void;
  onDragEnd: () => void;
  onSave: (note: Note) => void;
  onCancel: () => void;
}

const Lane: FC<LaneProps> = ({
  title,
  status,
  notes,
  editingNoteId,
  draggingNoteId,
  hoveredLane,
  onDragStart,
  onDragEnd,
  onDragOver,
  onDrop,
  setEditingNoteId,
  setHoveredLane,
  handleSave,
  color,
  onAddNote,
}) => (
  <div className="w-72">
    <div className={`h-10 flex justify-between items-center px-4 transition-colors duration-200 ${color} ${THEME_CLASSES.text.primary} bg-opacity-90 dark:bg-opacity-90`}>
      <h3 className="text-xs font-semibold truncate my-auto">
        {title}
      </h3>
      {title === 'Backlog' && (
        <button
          onClick={onAddNote}
          className="add-note-button bg-green-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-green-600 transition-colors"
          title="Add Note"
        >
          +
        </button>
      )}
    </div>
    <div
      className={`mt-4 p-4 overflow-y-auto flex flex-col gap-4 ${THEME_CLASSES.background} rounded-lg h-[calc(100vh-18rem)] border transition-all duration-200 ${draggingNoteId && hoveredLane === status
          ? 'border-2 border-dashed border-blue-300 dark:border-blue-400 bg-blue-100/50 dark:bg-blue-800/20'
          : 'border border-gray-200 dark:border-gray-700'
        }`}
      onDragOver={(e) => {
        onDragOver(e);
        setHoveredLane(status);
      }}
      onDragLeave={() => setHoveredLane(null)}
      onDrop={(e) => {
        onDrop(e, status);
        setHoveredLane(null);
      }}
    >
      {notes.map(note => (
        <NoteCard
          key={note.noteId}
          note={note}
          isEditing={editingNoteId === note.noteId}
          onEdit={() => setEditingNoteId(note.noteId)}
          onDragStart={(e) => onDragStart(e, note.noteId)}
          onDragEnd={onDragEnd}
          onSave={handleSave}
          onCancel={() => setEditingNoteId(null)}
        />
      ))}
    </div>
  </div>
);

const NoteCard: FC<NoteCardProps> = ({
  note,
  isEditing,
  onEdit,
  onDragStart,
  onDragEnd,
  onSave,
  onCancel
}) => (
  isEditing ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className={`w-full max-w-2xl ${THEME_CLASSES.background} rounded-lg shadow-xl flex flex-col`}>
        <div className={`flex items-center justify-between px-6 ${THEME_CLASSES.border} border-b`}>
          <h3 className={`text-lg py-4 font-medium ${THEME_CLASSES.text.primary}`}>
            Edit Note
          </h3>
          <button
            onClick={onCancel}
            className={`${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <NoteEditor
          note={note}
          content={note.content}
          type={note.type || 'Default'}
          onContentChange={(content) => {
            const updatedNote = { ...note, content };
            onSave(updatedNote);
          }}
          onTypeChange={(type) => {
            const updatedNote = { ...note, type };
            onSave(updatedNote);
          }}
          onSubmit={() => onCancel()}
          onCancel={onCancel}
        />
      </div>
    </div>
  ) : (
    <div
      className={`p-3 rounded-lg shadow-lg border-2 relative ${THEME_CLASSES.note.card.background.gray} border-blue-300 dark:border-blue-400 ${THEME_CLASSES.hover.background} transition-all cursor-move`}
      draggable
      onDragStart={(e) => onDragStart(e, note.noteId)}
      onDragEnd={onDragEnd}
    >
      <button
        onClick={onEdit}
        className={`absolute top-2 right-2 p-1 rounded ${THEME_CLASSES.hover.background} transition-colors`}
        title="Edit Note"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={THEME_CLASSES.text.primary}>
          <path d="M12 20h9"></path>
          <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
        </svg>
      </button>
      <h4 className={`text-sm font-semibold pr-8 ${THEME_CLASSES.text.primary}`}>{note.title}</h4>
      <p className={`text-xs ${THEME_CLASSES.text.secondary} mt-1`}>{note.content}</p>
    </div>
  )
);

const NotesKanban: FC<NoteComponentProps> = ({ type, id }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
  const [draggingNoteId, setDraggingNoteId] = useState<number | null>(null);
  const [hoveredLane, setHoveredLane] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const [noteType, setNoteType] = useState<typeof NOTE_TYPES[number]>('Default');

  const handleDragStart = useCallback((e: React.DragEvent, noteId: number) => {
    e.dataTransfer.setData('text/plain', noteId.toString());
    setDraggingNoteId(noteId);
  }, []);

  const handleDragEnd = useCallback(() => {
    setDraggingNoteId(null);
  }, []);

  const STATUSES = ['Backlog', 'To Do', 'In Progress', 'Done'];

  const getStatusId = (status: string): number => {
    return STATUSES.indexOf(status);
  };

  const handleDrop = useCallback(async (e: React.DragEvent, status: string) => {
    e.preventDefault();
    const noteId = parseInt(e.dataTransfer.getData('text/plain'), 10);

    const draggedNote = notes.find(note => note.noteId === noteId);
    if (!draggedNote) return;

    const oldStatus = draggedNote.status;
    if (oldStatus === status) return;

    const statusId = getStatusId(status);
    await noteService.updateNoteStatus(noteId, statusId);
    setNotes(prevNotes =>
      prevNotes.map(note =>
        note.noteId === noteId ? { ...note, status } : note
      )
    );

    // Post news about status change
    const title = draggedNote.title || '';
    const content = `updated the status of note "${trimTitle(title)}" from "${oldStatus}" to "${status}"`;
    await newsService.createNews(content, 'note', noteId, window.location.pathname);
  }, [notes]);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    const fetchNotes = async () => {
      const fetchedNotes = await noteService.getNotesByType(type, id);
      setNotes(fetchedNotes || []);
    };
    fetchNotes();
  }, [type, id]);

  const handleSave = async (note: Note): Promise<Note> => {
    try {
      // Update note on server
      await noteService.updateNote(note);

      // Update local state
      setNotes(prevNotes =>
        prevNotes.map(n => n.noteId === note.noteId ? note : n)
      );
      setEditingNoteId(null);

      return note;
    } catch (error) {
      console.error('Error updating note:', error);
      throw error;
    }
  };

  const getNotesByStatus = (status: string) =>
    notes.filter(note => note.status === status);

  const handleAddNote = () => {
    setIsModalOpen(true);
  };

  const saveNewNote = async () => {
    const newNote: Omit<Note, 'noteId'> = {
      title: noteContent.split('\n')[0] || 'Untitled Note',
      content: noteContent,
      status: 'Backlog',
      type: noteType,
      creatorId: 1,
      visibilityId: 1,
      createdAt: new Date(),
      statusId: 1,
      priorityId: null,
      dueDate: null,
      startDate: null,
      assignedTo: null,
      completedAt: null,
      versionId: 1,
      modifiedBy: 1,
      modifiedAt: new Date(),
      priority: null
    };
    try {
      await noteService.createNote(newNote);
      const createdNote: Note = {
        ...newNote,
        noteId: Date.now()
      };
      setNotes(prevNotes => [...prevNotes, createdNote]);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };

  return (
    <div className="flex gap-4 p-6 h-full overflow-x-auto">
      <Lane
        title="Backlog"
        status="Backlog"
        notes={getNotesByStatus('Backlog')}
        editingNoteId={editingNoteId}
        draggingNoteId={draggingNoteId}
        hoveredLane={hoveredLane}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        setEditingNoteId={setEditingNoteId}
        setHoveredLane={setHoveredLane}
        handleSave={handleSave}
        color={THEME_CLASSES.status.default}
        onAddNote={handleAddNote}
      />

      <Lane
        title="To Do"
        status="To Do"
        notes={getNotesByStatus('To Do')}
        editingNoteId={editingNoteId}
        draggingNoteId={draggingNoteId}
        hoveredLane={hoveredLane}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        setEditingNoteId={setEditingNoteId}
        setHoveredLane={setHoveredLane}
        handleSave={handleSave}
        color={THEME_CLASSES.status.todo}
        onAddNote={() => { }}
      />

      <Lane
        title="In Progress"
        status="In Progress"
        notes={getNotesByStatus('In Progress')}
        editingNoteId={editingNoteId}
        draggingNoteId={draggingNoteId}
        hoveredLane={hoveredLane}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        setEditingNoteId={setEditingNoteId}
        setHoveredLane={setHoveredLane}
        handleSave={handleSave}
        color={THEME_CLASSES.status.inProgress}
        onAddNote={() => { }}
      />

      <Lane
        title="Done"
        status="Done"
        notes={getNotesByStatus('Done')}
        editingNoteId={editingNoteId}
        draggingNoteId={draggingNoteId}
        hoveredLane={hoveredLane}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        setEditingNoteId={setEditingNoteId}
        setHoveredLane={setHoveredLane}
        handleSave={handleSave}
        color={THEME_CLASSES.status.done}
        onAddNote={() => { }}
      />

      {/* NoteEditor Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className={`w-full max-w-2xl ${THEME_CLASSES.background} rounded-lg shadow-xl flex flex-col`}>
            <div className={`flex items-center justify-between px-6 py-4 ${THEME_CLASSES.border} border-b`}>
              <h3 className={`text-lg py-4 font-medium ${THEME_CLASSES.text.primary}`}>
                Add Note
              </h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className={`${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`}
              >
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <NoteEditor
              content={noteContent}
              type={noteType}
              onContentChange={setNoteContent}
              onTypeChange={setNoteType}
              onSubmit={saveNewNote}
              onCancel={() => {
                setNoteContent('');
                setNoteType('Default');
                setIsModalOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotesKanban;
