import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../models/Account';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { highlightText } from '../../utils/highlightUtils';

interface AccountCardProps {
  account: Account;
  keywords: string[];
}

const AccountCard: FC<AccountCardProps> = ({ account, keywords }) => {

  const navigate = useNavigate();

  return (
    <div 
      className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 hover:shadow-xl transition-all cursor-pointer`}
      onClick={() => navigate(`/accounts/${account.id}`)}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-start">
          <h3 className={`text-sm font-semibold ${THEME_CLASSES.text.primary}`}>
            {highlightText(account.name, keywords)}
          </h3>
        </div>
        
        <div className="text-xs space-y-2">
          <div className="flex justify-between">
          
          </div>
          <div className="flex justify-between">
            <span className={THEME_CLASSES.text.secondary}>Phone:</span>
            <span className={THEME_CLASSES.text.secondary}>{account.phone}</span>
          </div>
          <div className="flex justify-between">
            <span className={THEME_CLASSES.text.secondary}>Address:</span>
            <span className={THEME_CLASSES.text.secondary}>{account.address}</span>
          </div>
          <div className="flex justify-between">
            <span className={THEME_CLASSES.text.secondary}>Created:</span>
            <span className={THEME_CLASSES.text.secondary}>{new Date(account.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AccountListProps {
  accounts: Account[];
  keywords: string[];
}

const AccountList: FC<AccountListProps> = ({ accounts, keywords }) => {
  return (
    <div className={`w-full h-fit grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-6 ${THEME_CLASSES.background}`}>
      {accounts.map((account) => (
        <AccountCard key={account.id} account={account} keywords={keywords} />
      ))}
    </div>
  );
};

export default AccountList;
