import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Contact } from '../../models/Contact';
import { Link } from 'react-router-dom';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { contactService } from '../../services/contactService';
import { getContactPhotoUrl } from '../../utils/avatarUtils';
import { useEventBus } from '../../context/EventBusContext';
import { noteService } from '../../services/noteService';
import { getNotesSummaryPrompt } from '../../utils/summaryUtils';

interface ContactCardsProps {
  searchValue: string;
}

const ContactCards: React.FC<ContactCardsProps> = ({
  searchValue
}) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const observer = useRef<IntersectionObserver>();

  const shouldLoadMore = useCallback(() => {
    if (loading) return false;
    return hasMore;
  }, [loading, hasMore]);

  const lastElementCallback = useCallback((node: HTMLElement | null) => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && shouldLoadMore()) {
        setPage(prevPage => prevPage + 1);
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [loading, shouldLoadMore]);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const PAGE_SIZE = 100;

  useEffect(() => {
    const loadContacts = async () => {
      setLoading(true);
      try {
        const newContacts = await contactService.getMyContacts();
        setContacts(prevContacts => {
          const updatedContacts = page === 1
            ? newContacts
            : [...prevContacts, ...newContacts];
          return Array.from(new Map<number, Contact>(updatedContacts.map(c => [c.id, c])).values());
        });
        setHasMore(newContacts.length === PAGE_SIZE);
      } catch (error) {
        console.error('Failed to fetch contacts', error);
      } finally {
        setLoading(false);
      }
    };

    setPage(1);
    loadContacts();
  }, [page, searchValue]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);
  const { publish } = useEventBus();

  const handleSummarize = async (contactId: number) => {
    const notes = await noteService.getNotesByContactId(contactId);
    if(!notes || !notes.length) return;
    const prompt = getNotesSummaryPrompt(notes);
    publish('chat:open', prompt);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Active':
        return 'bg-white dark:bg-gray-800';
      case 'Inactive':
        return 'bg-red-100 dark:bg-red-900';
      default:
        return 'bg-gray-100 dark:bg-gray-700';
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
      {contacts.map((contact, index) => (
        <div
          key={contact.id}
          ref={index === contacts.length - 1 ? lastElementCallback : null}
          className={`p-4 rounded-lg ${getStatusColor(contact.employeeStatus)} border ${THEME_CLASSES.border} hover:${THEME_CLASSES.hover.background} transition-colors duration-200 shadow-md hover:shadow-lg transition-shadow relative`}
        >
          <button
            onClick={async (e) => {
              e.stopPropagation();
              try {
                await contactService.deleteMyContact(contact.id);
                setContacts(prev => prev.filter(c => c.id !== contact.id));
              } catch (error) {
                console.error('Failed to delete contact', error);
              }
            }}
            className={`absolute top-2 right-2 p-1 rounded-full hover:${THEME_CLASSES.hover.background}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>

          <div
            className="flex items-stretch gap-4 cursor-pointer h-full"
            onClick={() => window.location.href = `/contacts/${contact.id}`}
          >
            <div className="flex-shrink-0 h-full">
              <div className={`w-24 h-full rounded-lg border-2 ${THEME_CLASSES.border} overflow-hidden`}>
                <img
                  src={getContactPhotoUrl(contact)}
                  alt={contact.displayName}
                  className="w-full h-full object-cover bg-gray-300 dark:bg-gray-600"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '';
                    target.className = 'w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center';
                  }}
                />
                <div className="w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center hidden">
                  <span className={`text-lg font-semibold ${THEME_CLASSES.text.primary}`}>
                    {contact.displayName?.[0] || '?'}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-1 min-w-0 space-y-1">
              <div className={`text-base font-semibold ${THEME_CLASSES.text.primary} hover:underline`}>
                <Link to={`/contacts/${contact.id}`}>
                  {contact.displayName}
                </Link>
              </div>
              <p className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                {contact.companyName}
              </p>
              <div className="pt-4 space-y-1">
                <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                  <span className="font-semibold">Email:</span> {contact.email}
                </div>
                <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                  <span className="font-semibold">Phone:</span> {contact.phone}
                </div>
                <div className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                  <span className="font-semibold">Status:</span> {contact.employeeStatus}
                </div>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                void handleSummarize(contact.id);
              }}
              className={`absolute bottom-2 right-2 p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
              title="Ask AI about this contact"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
              </svg>
            </button>
          </div>
        </div>
      ))}
      {loading && (
        <div className="col-span-full flex justify-center py-6">
          <div className={`animate-pulse ${THEME_CLASSES.text.secondary}`}>Loading...</div>
        </div>
      )}
    </div>

  );
};

export default ContactCards;
