import { useState, useEffect } from 'react';
import { Contact } from '../../models/Contact';
import { contactService } from '../../services/contactService';
import ContactTable from './ContactTable';

interface ContactListProps {
  searchValue: string;
  onSort: (column: keyof Contact, direction: 'asc' | 'desc') => void;
  sortField: keyof Contact;
  sortDirection: 'asc' | 'desc';
}

const ContactList: React.FC<ContactListProps> = ({
  searchValue,
  onSort,
  sortField,
  sortDirection
}) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalPages, setTotalPages] = useState<number>(1);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPage(1); // Reset to first page when changing page size
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const loadContacts = async () => {
      setLoading(true);
      try {
        const newContacts = await contactService.getAllContactsPaginated({
          page,
          pageSize,
          search: searchValue,
          sortBy: sortField,
          sortDirection
        });
        setContacts(newContacts);
        setTotalPages(Math.ceil(newContacts.length / pageSize));
      } catch (error) {
        console.error('Failed to fetch contacts', error);
      } finally {
        setLoading(false);
      }
    };

    setPage(1);
    loadContacts();
  }, [page, pageSize, searchValue, sortField, sortDirection]);

  return (
    <ContactTable
      contacts={contacts}
      loading={loading}
      onSort={onSort}
      sortField={sortField}
      sortDirection={sortDirection}
      pageSize={pageSize}
      onPageSizeChange={handlePageSizeChange}
      currentPage={page}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  );
};

export default ContactList;
