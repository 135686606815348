import { FC, useState, ChangeEvent, useEffect } from 'react';
import { noteService } from '../../services/noteService';
import { Note, NOTE_TYPES, NOTE_TYPE_ICONS } from '../../models/Note';
import { NoteComponentProps } from '../../models/Note';
import { highlightText } from '../../utils/highlightUtils';
import NoteEditor from './NoteEditor';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface ExtendedNote extends Note {
  type: typeof NOTE_TYPES[number];
}

const NotesHistory: FC<NoteComponentProps> = ({
  type,
  id
}) => {
  const [selectedType, setSelectedType] = useState<typeof NOTE_TYPES[number]>('All');
  const [notesSearchTerm, setNotesSearchTerm] = useState('');
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    const fetchNotes = async () => {
      const notes = await noteService.getNotesByType(type, id);
      setNotes(notes);
    };
    fetchNotes();
  }, [type, id]);

  const filteredNotes = (notes as ExtendedNote[])
    .filter(note => selectedType === 'All' || note.type === selectedType)
    .filter(note =>
      note.title.toLowerCase().includes(notesSearchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(notesSearchTerm.toLowerCase())
    );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNotesSearchTerm(e.target.value);
  };

  const handleTypeSelect = (type: typeof NOTE_TYPES[number]) => {
    setSelectedType(type);
  };

  const handleEditNote = (noteId: number) => {
    setEditingNoteId(noteId);
  };

  const handleCancelEdit = () => {
    setEditingNoteId(null);
  };

  const [editedNote, setEditedNote] = useState<Note | null>(null);
  const [noteContent, setNoteContent] = useState('');
  const [noteType, setNoteType] = useState<typeof NOTE_TYPES[number]>('Default');

  const handleSave = async (note: Note): Promise<Note> => {
    try {
      // Update note on server
      await noteService.updateNote(note);
      
      // Update local state
      setNotes(prevNotes =>
        prevNotes.map(n => n.noteId === note.noteId ? note : n)
      );
      setEditingNoteId(null);
      
      return note;
    } catch (error) {
      console.error('Error updating note:', error);
      throw error;
    }
  };

  return (
    <div id="notes-history-container" className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <div id="notes-header-section" className="space-y-4 mb-6">
        <div id="notes-controls" className="flex flex-col md:flex-row gap-4">
          <div id="search-container" className="relative flex-1">
            <div id="search-icon-container" className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg id="search-icon" className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              id="notes-search-input"
              type="search"
              placeholder="Search notes..."
              value={notesSearchTerm}
              onChange={handleSearchChange}
              className="block w-full pl-11 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-xs font-medium"
            />
          </div>

          <div id="type-filters" className="flex gap-2 overflow-x-auto">
            {NOTE_TYPES.map(type => (
              <button
                id={`type-filter-${type.toLowerCase()}`}
                key={type}
                onClick={() => handleTypeSelect(type)}
                className={`px-3 py-1 rounded-full text-xs font-medium ${selectedType === type
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Title</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Type</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Assigned To</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Due Date</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Status</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Priority</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Content</th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
            {filteredNotes.map(note => (
              <tr 
                key={note.noteId} 
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={(e) => {
                  // Prevent row click if clicking menu button or menu items
                  if ((e.target as HTMLElement).closest('button')) {
                    return;
                  }
                  handleEditNote(note.noteId);
                }}
              >
                {editingNoteId === note.noteId ? (
                  <td colSpan={8} className="p-0">
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                      <div className={`w-full max-w-2xl ${THEME_CLASSES.background} rounded-lg shadow-xl flex flex-col`}>
                        <div className={`flex items-center justify-between px-6 py-4 ${THEME_CLASSES.border} border-b`}>
                          <h3 className={`text-lg font-medium ${THEME_CLASSES.text.primary}`}>
                            Edit Note
                          </h3>
                          <button
                            onClick={handleCancelEdit}
                            className={`${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`}
                          >
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                        <NoteEditor
                          note={note}
                          content={note.content}
                          type={note.type || 'Default'}
                          onContentChange={(content) => {
                            const updatedNote = { ...note, content };
                            handleSave(updatedNote);
                          }}
                          onTypeChange={(type) => {
                            const updatedNote = { ...note, type };
                            handleSave(updatedNote);
                          }}
                          onSubmit={() => handleCancelEdit()}
                          onCancel={handleCancelEdit}
                        />
                      </div>
                    </div>
                  </td>
                ) : (
                  <>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                      {highlightText(note.title, notesSearchTerm.split(' '))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-4 h-4"
                          dangerouslySetInnerHTML={{ __html: NOTE_TYPE_ICONS[note.type as keyof typeof NOTE_TYPE_ICONS] || NOTE_TYPE_ICONS.All }}
                        />
                        {note.type}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {note.assignedTo || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {note.dueDate ? new Date(note.dueDate).toLocaleDateString() : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {note.status || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {note.priority || '-'}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                      {highlightText(note.content || '', notesSearchTerm.split(' '))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                      <button
                        onClick={() => setOpenMenuId(openMenuId === note.noteId ? null : note.noteId)}
                        className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg className="w-4 h-4 text-gray-500" viewBox="0 0 16 16" fill="currentColor">
                          <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                      </button>

                      {openMenuId === note.noteId && (
                        <div className="absolute right-0 mr-1 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
                          <div className="py-1" role="menu">
                            <button
                              onClick={() => {
                                handleEditNote(note.noteId);
                                setOpenMenuId(null);
                              }}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              role="menuitem"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => setOpenMenuId(null)}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              role="menuitem"
                            >
                              Make a copy
                            </button>
                            <button
                              onClick={() => setOpenMenuId(null)}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              role="menuitem"
                            >
                              Favorite
                            </button>
                            <div className="relative group">
                              <button
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 flex justify-between items-center"
                                role="menuitem"
                              >
                                <span>Labels</span>
                                <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                              </button>
                            </div>
                            <button
                              onClick={() => setOpenMenuId(null)}
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 flex justify-between items-center"
                              role="menuitem"
                            >
                              <span>Delete</span>
                              <span className="text-xs text-gray-400">⌘⌫</span>
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotesHistory;
