type DeepObject = { [key: string]: any };

export function convertKeysToCamelCase<T extends DeepObject>(obj: T): DeepObject {
  // Handle null/undefined
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    // Convert the key to camelCase
    const camelKey = key
      // Handle special case for 'ID' -> 'id'
      .replace(/^ID$/, 'id')
      // Split on any non-alphanumeric character or capital letter
      .split(/[\W_]+|(?=[A-Z])/)
      // Convert to camelCase
      .map((word, index) => {
        // Convert to lowercase first
        const lowercaseWord = word.toLowerCase();
        // Capitalize first letter for all except first word
        return index === 0
          ? lowercaseWord
          : lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
      })
      .join('');

    // Recursively convert nested objects
    const newValue = typeof value === 'object' ? convertKeysToCamelCase(value) : value;

    return {
      ...acc,
      [camelKey]: newValue
    };
  }, {});
}