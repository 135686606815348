import { Account } from '../models/Account';
import { apiService } from './apiService';

const ACCOUNTS_ENDPOINT = '/api/data/accounts';

export async function getAccounts(): Promise<Account[]> {
  return apiService<Account[]>(ACCOUNTS_ENDPOINT);
}

export async function getAccount(id: number): Promise<Account> {
  return apiService<Account>(`${ACCOUNTS_ENDPOINT}/${id}`);
}

export async function createAccount(account: Omit<Account, 'id'>): Promise<Account> {
  return apiService<Account>(ACCOUNTS_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(account),
  });
}

export async function updateAccount(id: number, account: Partial<Account>): Promise<Account> {
  return apiService<Account>(`${ACCOUNTS_ENDPOINT}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(account),
  });
}

export async function deleteAccount(id: number): Promise<void> {
  await apiService<void>(`${ACCOUNTS_ENDPOINT}/${id}`, {
    method: 'DELETE',
  });
}

export const accountService = {
  getAccounts,
  getAccount,
  createAccount,
  updateAccount,
  deleteAccount,
};
