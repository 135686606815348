import { FC } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { THEME_CLASSES } from '../../constants/themeConstants';
import OpportunityWizard from './OpportunityWizard';

interface EditOpportunityProps {
  opportunity?: Opportunity;
  onClose: () => void;
  initialStageId: number;
}

const EditOpportunity: FC<EditOpportunityProps> = ({
  opportunity,
  onClose,
  initialStageId
}) => {
  return (
    <div className={`p-8 rounded-lg w-[800px] h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
      <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
          {opportunity ? 'Edit Opportunity' : 'Add New Opportunity'}
        </h3>
        <button
          type="button"
          onClick={onClose}
          className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
          aria-label="Close"
        >
          ×
        </button>
      </div>
      <OpportunityWizard
        opportunity={opportunity}
        onClose={onClose}
        initialStageId={initialStageId}
      />
    </div>
  );
};

export default EditOpportunity;
