import { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { pipelineService } from '../../services/pipelineService';
import { opportunityService } from '../../services/opportunityService';
import { newsService } from '../../services/newsService';
import { trimTitle } from '../../utils/stringUtils';
import { PipelineStage } from '../../models/Pipeline';
import { Opportunity } from '../../models/Opportunity';
import { THEME_CLASSES } from '../../constants/themeConstants';
import OpportunityWizard from './OpportunityWizard';
import OpportunityTable from './OpportunityTable';
import EditOpportunity from './EditOpportunity';
import OpportunityNotes from './OpportunityNotes';

interface OpportunityCardProps {
  opportunity: Opportunity;
  onDragStart: (id: number) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>, stageId: number) => void;
  onDrop: (stageId: number) => void;
  setEditingOpportunity: (opportunity: Opportunity, viewNotes: boolean) => void;
  draggingId: number | null;
}

const OpportunityCard: FC<OpportunityCardProps> = ({
  opportunity,
  onDragStart,
  onDragOver,
  onDrop,
  setEditingOpportunity,
  draggingId
}) => {
  return (
    <div
      draggable
      onDragStart={() => onDragStart(opportunity.id)}
      onDragOver={(e) => onDragOver(e, opportunity.stageId)}
      onDrop={() => onDrop(opportunity.stageId)}
      className={`p-3 rounded-lg shadow-lg border-2 ${THEME_CLASSES.note.card.background.gray} border-green-500 dark:border-green-600 ${THEME_CLASSES.hover.background} transition-all cursor-move relative ${draggingId === opportunity.id ? 'opacity-0' : ''
        }`}
    >
      <div className="absolute top-1 right-1 flex gap-1">
        <button
          onClick={(e) => {
            e.stopPropagation();
            const eventTitle = `RE: ${opportunity.title}`;
            const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(eventTitle)}`;
            window.open(calendarUrl, '_blank');
          }}
          className={`p-1 ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
          title="Add to Google Calendar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            const subject = `RE: ${opportunity.title}`;
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
            window.open(gmailUrl, '_blank');
          }}
          className={`p-1 ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
          title="Compose Gmail"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            setEditingOpportunity(opportunity, false);
          }}
          className="p-1 text-gray-500 hover:text-green-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setEditingOpportunity(opportunity, true);
          }}
          className="p-1 text-gray-500 hover:text-blue-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      <p className="text-xs font-medium text-gray-900 dark:text-white">
        {opportunity.title}
      </p>
      <p className="text-[0.7rem] text-gray-500 dark:text-gray-400">
        Value: ${opportunity.value?.toLocaleString() || '0'}
      </p>
      <div className="mt-1 grid grid-cols-2 gap-x-2 gap-y-1">
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
          Markets:
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
          {opportunity.markets}
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
          Province:
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
          {opportunity.province}
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
          Type:
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
          {opportunity.salesType}
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
          Agents:
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
          {opportunity.numberOfAgents}
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-left">
          Probability:
        </p>
        <p className="text-[0.7rem] text-gray-500 dark:text-gray-400 text-right">
          {opportunity.probability}%
        </p>
      </div>
    </div>
  );
};

interface PipelineDetailsProps {
  pipeline: {
    id: number;
    name: string;
  } | null;
}

const PipelineDetails: FC<PipelineDetailsProps> = ({ pipeline }) => {
  type ModalState = {
    isOpen: boolean;
    opportunity: Opportunity | null;
    viewNotes: boolean;
  };

  const navigate = useNavigate();
  const [stages, setStages] = useState<PipelineStage[]>([]);
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [draggingId, setDraggingId] = useState<number | null>(null);
  const [hoveredStageId, setHoveredStageId] = useState<number | null>(null);
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    opportunity: null,
    viewNotes: false
  });
  const location = useLocation();
  const [viewMode, setViewMode] = useState<'table' | 'board'>(
    location.pathname.endsWith('/table') ? 'table' : 'board'
  );

  const handleUpdateOpportunity = useCallback(async (opportunity: Opportunity) => {
    if (!pipeline) return false;

    try {
      opportunityService.updateOpportunityStage(
        opportunity.id,
        opportunity.stageId
      );
      setOpportunities(opportunities);
      return true;
    } catch (err) {
      console.error('Failed to update opportunity:', err);
      return false;
    }
  }, [pipeline]);

  const handleDragStart = useCallback((id: number) => {
    setDraggingId(id);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, stageId: number) => {
    e.preventDefault();
    setHoveredStageId(stageId);
  }, []);

  const handleDrop = useCallback(async (stageId: number) => {
    if (!draggingId || !pipeline) return;
    setHoveredStageId(null);

    try {
      // Get the opportunity being moved and its old stage
      const opportunity = opportunities.find(opp => opp.id === draggingId);
      const oldStage = stages.find(stage => stage.id === opportunity?.stageId);
      const newStage = stages.find(stage => stage.id === stageId);

      if (!opportunity || !oldStage || !newStage || oldStage.id === newStage.id) return;

      // Optimistically update local state
      setOpportunities(prev => {
        return prev.map(opp => ({
          ...opp,
          stageId: opp.id === draggingId ? stageId : opp.stageId
        }));
      });

      // Make API call in background
      opportunityService.updateOpportunityStage(draggingId, stageId)
        .then(() => {
          // Post news update after successful stage change
          const content = `Updated the stage of opportunity "${trimTitle(opportunity.title)}" from "${oldStage.stageName}" to "${newStage.stageName}"`;
          newsService.createNews(content, 'opportunity', opportunity.id, window.location.pathname);
        })
        .catch(err => {
          console.error('Failed to update opportunity stage:', err);
          // Revert optimistic update if API call fails
          setOpportunities(prev => {
            return prev.map(opp => {
              if (opp.id === draggingId) {
                return { ...opp, stageId: opp.stageId }; // Revert to original stageId
              }
              return opp;
            });
          });
        });
    } catch (err) {
      console.error('Failed to update opportunity stage:', err);
    } finally {
      setDraggingId(null);
    }
  }, [draggingId, pipeline]);

  const handleCreateOpportunity = useCallback(async (opportunity: Omit<Opportunity, 'id'>) => {
    if (!pipeline || !stages.length) return false;

    try {
      const opportunityWithStage = {
        ...opportunity,
        stageId: stages[0].id // Use first stage ID
      };
      const newOpportunity = await pipelineService.createOpportunity(pipeline.id, opportunityWithStage);
      setOpportunities(prev => {
        return [...prev, newOpportunity];
      });
      return true;
    } catch (err) {
      console.error('Failed to create opportunity:', err);
      return false;
    }
  }, [pipeline, stages]);

  useEffect(() => {
    if (!pipeline) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [stages, opportunities] = await Promise.all([
          pipelineService.getPipelineStages(pipeline.id),
          pipelineService.getPipelineOpportunities(pipeline.id)
        ]);
        setStages(stages || []);
        setOpportunities(opportunities || []);
      } catch (err) {
        setError('Failed to load pipeline data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pipeline]);

  if (!pipeline || !stages.length) {
    return (
      <div className="p-4">
        <p className="text-gray-500">Select a pipeline with stages to view details</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }
  return (
    <div className="p-4 flex flex-col h-full">
      <div className="flex items-center mb-4 relative w-full">
        <div className="absolute left-0">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white my-auto">
            {pipeline.name}
          </h2>
        </div>
        <div className="flex gap-2 items-center mx-auto">
          <button
            onClick={() => {
              setViewMode('board');
              navigate(`/pipelines/${pipeline.id}/board`);
            }}
            className={`rounded-full py-1 px-4 text-xs font-medium ${viewMode === 'board'
              ? `${THEME_CLASSES.button.primary} font-bold`
              : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            Board
          </button>
          <button
            onClick={() => {
              setViewMode('table');
              navigate(`/pipelines/${pipeline.id}/table`);
            }}
            className={`rounded-full py-1 px-4 text-xs font-medium ${viewMode === 'table'
              ? `${THEME_CLASSES.button.primary} font-bold`
              : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            Table
          </button>
        </div>
        <button
          onClick={() => setModalState({ isOpen: true, opportunity: null, viewNotes: false })}
          className="px-4 py-2 text-xs font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
        >
          Add Opportunity
        </button>
      </div>

      <dialog
        open={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false, opportunity: null, viewNotes: false })}
        className="fixed inset-0 z-50 bg-transparent"
      >
        <div className="fixed inset-0 bg-black/50" />
        <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-lg w-[1200px] h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
          <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
              {modalState.viewNotes ? 'Opportunity Notes' : (modalState.opportunity ? 'Edit Opportunity' : 'Add New Opportunity')}
            </h3>
            <button
              type="button"
              onClick={() => setModalState({ isOpen: false, opportunity: null, viewNotes: false })}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          {modalState.viewNotes ? (
            <OpportunityNotes 
              opportunity={modalState.opportunity!}
              onClose={() => setModalState({ isOpen: false, opportunity: null, viewNotes: false })}
            />
          ) : (
            <EditOpportunity
              opportunity={modalState.opportunity || undefined}
              onClose={() => setModalState({ isOpen: false, opportunity: null, viewNotes: false })}
              initialStageId={stages[0]?.id}
            />
          )}
        </div>
      </dialog>

      {viewMode === 'board' ? (
        <div className="overflow-x-auto">
          <div className="flex min-w-max">
          {stages.map((stage, index) => (
            <div key={stage.id} className="w-72">
              <div className={`h-10 flex items-center px-4 transition-colors duration-200 ${hoveredStageId === stage.id
                ? 'bg-pink-500/40 dark:bg-pink-600/30'
                : 'bg-green-500/40 dark:bg-green-600/30'
                } ${index === 0
                  ? '[clip-path:polygon(4px_0,calc(100%-12px)_0,100%_50%,calc(100%-12px)_100%,4px_100%,4px_0)]'
                  : index === stages.length - 1
                    ? '[clip-path:polygon(0_0,calc(100%-4px)_0,calc(100%-4px)_100%,0_100%,12px_50%)]'
                    : '[clip-path:polygon(0_0,calc(100%-12px)_0,100%_50%,calc(100%-12px)_100%,0_100%,12px_50%)]'
                }`}>
                <h3 className="text-gray-800 dark:text-gray-100 text-xs font-semibold truncate mx-auto my-auto">
                  {stage.stageName}
                </h3>
              </div>
              <div
                onDragOver={(e) => handleDragOver(e, stage.id)}
                onDrop={() => handleDrop(stage.id)}
                onDragLeave={() => setHoveredStageId(null)}
                className={`mt-4 p-4 bg-white dark:bg-gray-800 rounded-lg h-[calc(100vh-16rem)] border transition-all duration-200 overflow-y-auto ${hoveredStageId === stage.id
                  ? 'border-2 border-dashed border-green-500 dark:border-green-600 bg-green-50/50 dark:bg-green-900/20'
                  : 'border border-gray-200 dark:border-gray-700'
                  }`}
              >
                <div className="space-y-2">
                  <div className="space-y-2 h-full min-h-[2rem]">
                    {opportunities
                      .filter(opp => opp.stageId === stage.id)
                      .map(opp => (
                        <OpportunityCard
                          key={opp.id}
                          opportunity={opp}
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          setEditingOpportunity={(opp, viewNotes) => setModalState({ isOpen: true, opportunity: opp, viewNotes })}
                          draggingId={draggingId}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      ) : (
        <OpportunityTable
          opportunities={opportunities}
          setEditingOpportunity={(opp) => setModalState({ isOpen: true, opportunity: opp, viewNotes: false })}
        />
      )}
    </div>
  );
};

export default PipelineDetails;
