import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactList from '../components/contact/ContactList';
import ContactCards from '../components/contact/ContactCards';
import { THEME_CLASSES } from '../constants/themeConstants';
import { Contact } from '../models/Contact';

function ContactSearch() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeTab = pathname.endsWith('/all') ? 'all' : 'my';
  const [_, setActiveTab] = useState<'my' | 'all'>(activeTab);
  const [sortField, setSortField] = useState<keyof Contact>('displayName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [contactFilter, setContactFilter] = useState<'type1' | 'type0' | 'all'>('all');

  const FILTER_LABELS = {
    type1: 'RLP',
    type0: 'CRM', 
    all: 'All'
  };

  const handleSort = (column: keyof Contact, direction: 'asc' | 'desc') => {
    setSortField(column);
    setSortDirection(direction);
  };

  return (
    <div className='h-full flex flex-col'>
      <div className="flex items-center justify-between mb-6 mx-6">
        <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary}`}>Contacts</h1>
        
        <div className="flex gap-2">
          <button
            onClick={() => {
              setActiveTab('my');
              navigate('/contacts/my');
            }}
            className={`px-4 py-1 text-xs font-medium rounded-full ${activeTab === 'my'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            My Contacts
          </button>
          <button
            onClick={() => {
              setActiveTab('all');
              navigate('/contacts/all');
            }}
            className={`px-4 py-1 text-xs font-medium rounded-full ${activeTab === 'all'
                ? `${THEME_CLASSES.button.primary} font-bold`
                : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
              }`}
          >
            All Contacts
          </button>
        </div>

        <div className="flex items-center gap-4">
          <div className="w-64 relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search contacts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
            />
            {searchQuery && (
              <button
                onClick={() => setSearchQuery('')}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>

          <div className="flex items-center space-x-2">
            <span className={`text-sm ${THEME_CLASSES.text.secondary}`}>
              {FILTER_LABELS[contactFilter]}
            </span>
            <button
              onClick={() => {
                setContactFilter(current => 
                  current === 'type1' ? 'type0' :
                  current === 'type0' ? 'all' : 'type1'
                );
              }}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                contactFilter === 'type1' ? 'bg-red-500' :
                contactFilter === 'type0' ? 'bg-blue-400' : 'bg-gray-200 dark:bg-gray-700'
              }`}
            >
              <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                contactFilter === 'type1' ? 'translate-x-6' :
                contactFilter === 'type0' ? 'translate-x-3' : 'translate-x-1'
              }`} />
            </button>
          </div>
        </div>
      </div>

      {activeTab === 'my' ? (
        <ContactCards
          searchValue={searchQuery}
        />
      ) : (
        <ContactList
          searchValue={searchQuery}
          onSort={handleSort}
          sortField={sortField}
          sortDirection={sortDirection}
        />
      )}
    </div>
  );
}

export default ContactSearch;
