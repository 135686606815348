import { FC, useState, useEffect } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';
import { Note, NOTE_TYPE_COLORS, NOTE_TYPES } from '../models/Note';
import { noteService } from '../services/noteService';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from '../components/common/Spinner';

interface NotesPageProps {}

const NotesPage: FC<NotesPageProps> = () => {
  const { tab } = useParams<{ tab?: string }>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(
    tab || NOTE_TYPES[0] // Default to first type ('All')
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    const fetchNotes = async () => {
      setIsLoading(true);
      try {
        // Get all notes and then filter by type
        const fetchedNotes = await noteService.getNotes('account'); // Get all notes from account context
        const filteredNotes = activeTab === 'All' 
          ? fetchedNotes 
          : fetchedNotes.filter(note => note.type === activeTab);
        setNotes(filteredNotes || []);
      } catch (error) {
        console.error('Failed to fetch notes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotes();
  }, [activeTab]);

  const filteredNotes = notes.filter(note =>
    note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    note.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isLoading) return (
    <div className="fixed inset-0 flex items-center justify-center">
      <Spinner />
    </div>
  );

  return (
    <div className='h-full flex flex-col'>
      <div className={`${THEME_CLASSES.background} border-b ${THEME_CLASSES.border}`}>
        <div className="mx-auto">
          <div className="px-4">
            <div className="flex flex-row items-center gap-4 mb-6">
              <h1 className={`text-lg my-auto font-semibold ${THEME_CLASSES.text.primary}`}>
                Notes
              </h1>
              
              <div className="flex-1 flex justify-center">
                <nav className="flex flex-wrap gap-2 mx-auto" aria-label="Tabs">
                  {NOTE_TYPES.map(type => (
                    <button
                      key={type}
                      onClick={() => {
                        setActiveTab(type);
                        navigate(`/notes/${type.toLowerCase()}`);
                      }}
                      className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${
                        activeTab === type
                          ? `${THEME_CLASSES.button.primary} font-bold`
                          : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                    >
                      {type}
                    </button>
                  ))}
                </nav>
              </div>

              <div className="relative max-w-xs">
                <input
                  type="text"
                  placeholder="Search notes..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={`w-full h-8 pl-8 pr-4 rounded-lg text-sm ${THEME_CLASSES.input.base} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
                />
                <svg
                  className="absolute left-2.5 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>

              {/* <div className="flex gap-2">
                <button
                  onClick={() => {
                    const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent('New Note')}`;
                    window.open(calendarUrl, '_blank');
                  }}
                  className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                  title="Add to Google Calendar"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </button>

                <button
                  onClick={() => {
                    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent('Notes Summary')}`;
                    window.open(gmailUrl, '_blank');
                  }}
                  className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                  title="Compose Gmail"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </button>

                <button
                  onClick={() => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([JSON.stringify(notes, null, 2)], { type: 'application/json' }));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = 'notes.json';
                    link.click();
                    window.URL.revokeObjectURL(downloadUrl);
                  }}
                  className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                  title="Export Notes"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                </button>
              </div> */}
            </div>
            <div className={`h-px w-full ${THEME_CLASSES.border} mt-4`}></div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-4 overflow-auto">
        {/* Notes Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredNotes.map(note => (
            <div
              key={note.noteId}
              className={`p-4 rounded-lg ${THEME_CLASSES.background} border ${THEME_CLASSES.border} hover:shadow-lg transition-shadow duration-200`}
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className={`font-medium ${THEME_CLASSES.text.primary} truncate`}>
                  {note.title}
                </h3>
                <span className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                  {new Date(note.createdAt).toLocaleDateString()}
                </span>
              </div>
              <p className={`text-sm ${THEME_CLASSES.text.secondary} line-clamp-3 mb-3`}>
                {note.content}
              </p>
              <div className="flex items-center justify-between">
                {note.type && (
                  <span className={`inline-block px-2 py-1 text-xs font-medium rounded-full ${NOTE_TYPE_COLORS[note.type as keyof typeof NOTE_TYPE_COLORS]}`}>
                    {note.type}
                  </span>
                )}
                {note.status && (
                  <span className={`text-xs ${THEME_CLASSES.text.secondary}`}>
                    {note.status}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {filteredNotes.length === 0 && !isLoading && (
          <div className="flex flex-col items-center justify-center py-12">
            <svg
              className={`w-12 h-12 ${THEME_CLASSES.text.secondary} mb-4`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            <p className={`text-sm ${THEME_CLASSES.text.secondary}`}>
              No notes found
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesPage;
