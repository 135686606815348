import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Lead } from '../models/Lead';
import { THEME_CLASSES } from '../constants/themeConstants';
import OpportunityWizard from '../components/pipeline/OpportunityWizard';
import Spinner from '../components/common/Spinner';
import { leadService } from '../services/leadService';

interface LeadCardProps {
  lead: Lead;
}

import { highlightText } from '../utils/highlightUtils';

const CreateLeadCard: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 mb-4 w-72 h-48 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200`}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 ${THEME_CLASSES.text.secondary} mb-2`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
      </svg>
      <span className={`text-sm ${THEME_CLASSES.text.secondary}`}>Create Lead</span>
    </div>
  );
};

interface LeadCardPropsExtended extends LeadCardProps {
  keywords: string[];
  onCreateOpportunity: (lead: Lead) => void;
  onEditLead: (lead: Lead) => void;
}

const LeadCard: FC<LeadCardPropsExtended> = React.memo(({ lead, keywords, onCreateOpportunity, onEditLead }) => {
  return (
    <div className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 mb-4 w-72 h-48 flex flex-col relative`}>
      <div className={`text-sm ${THEME_CLASSES.text.primary} mb-2 pr-8 whitespace-pre-wrap`}>
        <span className="font-semibold">
          {highlightText(lead.description.split('\n')[0], keywords)}
        </span>
        {lead.description.split('\n').length > 1 && (
          <span className="font-normal">
            {'\n' + lead.description.split('\n').slice(1).join('\n')}
          </span>
        )}
      </div>
      <div className="mt-auto">
        <p className={`text-xs ${THEME_CLASSES.text.secondary}`}>
          Source: {highlightText(lead.origination, keywords)}
        </p>
        <p className={`text-xs ${THEME_CLASSES.text.secondary}`}>
          Updated: {lead.updatedAt ? highlightText(new Date(lead.updatedAt).toLocaleDateString(), keywords) : 'N/A'}
        </p>
      </div>
      <button 
        className={`absolute top-2 right-4 p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200`}
        onClick={() => onEditLead(lead)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </button>
      <button
        className={`absolute bottom-4 right-4 p-1.5 rounded-full ${THEME_CLASSES.button.primary} transition-colors duration-200`}
        onClick={() => onCreateOpportunity(lead)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
        </svg>
      </button>
    </div>
  );
});

const Leads: FC = () => {
  // State management
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Search state
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem('leads-search') || '';
  });

  // Dialog states
  const [showLeadDialog, setShowLeadDialog] = useState(false);
  const [showOpportunityDialog, setShowOpportunityDialog] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  // Handlers wrapped in useCallback
  const handleCreateLead = useCallback(() => {
    setSelectedLead(null);
    setShowLeadDialog(true);
  }, []);

  const handleCreateOpportunity = useCallback((lead: Lead) => {
    setSelectedLead(lead);
    setShowOpportunityDialog(true);
  }, []);

  const handleEditLead = useCallback((lead: Lead) => {
    setSelectedLead(lead);
    setShowLeadDialog(true);
  }, []);

  const handleCloseLeadDialog = useCallback(() => {
    setShowLeadDialog(false);
  }, []);

  const handleCloseOpportunityDialog = useCallback(() => {
    setShowOpportunityDialog(false);
  }, []);

  // Save search to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('leads-search', searchQuery);
  }, [searchQuery]);

  const filteredLeads = useMemo(() => {
    const trimmedQuery = searchQuery.trim();
    if (!leads) return [];

    if (!trimmedQuery) return leads;

    const keywords = trimmedQuery
      .toLowerCase()
      .split(' ')
      .map(k => k.trim())
      .filter(k => k.length > 0);

    return leads.filter(lead => {
      const searchableFields = [
        lead.description?.toString() || '',
        lead.origination?.toString() || '',
        lead.updatedAt?.toString() || ''
      ].map(field => field.toLowerCase());

      return keywords.every(keyword =>
        searchableFields.some(field => field.includes(keyword))
      );
    });
  }, [leads, searchQuery]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const leadsData = await leadService.getLeads();
        setLeads(leadsData);
      } catch (err) {
        setError('Failed to load leads');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);


  if (loading) {
    return (
      <div className="p-6">
        <h1 className="text-xl font-semibold">Leads</h1>
        <div className="mt-4 flex justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <h1 className="text-xl font-semibold">Leads</h1>
        <div className="mt-4 text-red-500">{error}</div>
      </div>
    );
  }

  const renderSearchBar = () => (
    <div className="w-96 relative ml-auto">
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
        </svg>
      </div>
      <input
        type="text"
        placeholder="Search leads..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
      />
      {searchQuery && (
        <button
          onClick={() => setSearchQuery('')}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      )}
    </div>
  );

  const renderLeadCards = () => (
    <div className="overflow-y-auto max-h-[calc(100vh-150px)] p-2 flex flex-wrap gap-4">
      <CreateLeadCard onClick={handleCreateLead} />
      {filteredLeads.length > 0 ? (
        filteredLeads.map((lead) => (
          <LeadCard
            key={lead.id}
            lead={lead}
            keywords={searchQuery
              .toLowerCase()
              .split(' ')
              .map(k => k.trim())
              .filter(k => k.length > 0)}
            onCreateOpportunity={handleCreateOpportunity}
            onEditLead={handleEditLead}
          />
        ))
      ) : (
        <p className="text-gray-500 dark:text-gray-400">No leads found</p>
      )}
    </div>
  );

  const renderLeadDialog = () => (
    <dialog
      open={showLeadDialog}
      onClose={handleCloseLeadDialog}
      className="fixed inset-0 z-50 bg-transparent"
    >
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
        <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white pl-4 pt-4">
            {selectedLead ? 'Edit Lead' : 'Create Lead'}
          </h3>
          <button
            type="button"
            onClick={handleCloseLeadDialog}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-full mr-4"
            aria-label="Close"
          >
            ×
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <label className={`block text-sm font-medium mb-1 ${THEME_CLASSES.text.primary}`}>
              Description
            </label>
            <textarea
              rows={18}
              className={`w-full px-3 py-2 border rounded-md text-sm ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
              placeholder="Enter lead description"
              defaultValue={selectedLead?.description || ''}
            />
          </div>
          <div>
            <label className={`block text-sm font-medium mb-1 ${THEME_CLASSES.text.primary}`}>
              Source
            </label>
            <select
              className={`w-full px-3 py-2 border rounded-md text-sm ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text}`}
              defaultValue={selectedLead?.origination || ''}
            >
              <option value="Direct call/follow up">Direct call/follow up</option>
              <option value="Email campaign">Email campaign</option>
              <option value="Reach out from prospect">Reach out from prospect</option>
              <option value="betteratroyallepage.ca">betteratroyallepage.ca</option>
              <option value="Existing franchisee">Existing franchisee</option>
              <option value="other prospect">other prospect</option>
            </select>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleCloseLeadDialog}
              className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.secondary}`}
            >
              Cancel
            </button>
            <button
              onClick={() => { }}
              className={`px-4 py-2 text-sm font-medium rounded-md ${THEME_CLASSES.button.primary}`}
            >
              {selectedLead ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );

  return (
    <div className="p-6">
      <div className="flex items-center mb-6">
        <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} my-auto`}>Leads</h1>
        {renderSearchBar()}
      </div>
      {renderLeadCards()}
      {renderLeadDialog()}
      {showOpportunityDialog && (
        <OpportunityWizard
          onClose={handleCloseOpportunityDialog}
        />
      )}
    </div>
  );
};

export default Leads;
