import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { isAuthenticated as checkAuth } from './services/authService';
import Login from './pages/Login';
import Chat from './pages/Chat';
import Navbar from './components/Navbar';
import Leads from './pages/Leads';
import Events from './pages/Events';
import Dashboard from './pages/Dashboard';
import Contacts from './pages/Contacts';
import Accounts from './pages/Accounts';
import AccountPage from './pages/Account';
import Pipelines from './pages/Pipelines';
import NotesPage from './pages/Notes';
import Sidebar from './components/Sidebar';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { NotificationProvider } from './context/NotificationContext';
import { EventBusProvider, useEventBus } from './context/EventBusContext';
import { THEME_COLORS, THEME_CLASSES, BREAKPOINTS } from './constants/themeConstants';
import ContactPage from './pages/Contact';
import { Contact } from './models/Contact';
import { Notification } from './components/common/Notification';

// Wrapper component to access location
const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDark } = useTheme();

  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loadingAttempts, setLoadingAttempts] = useState<number>(0);
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(() => {
    const saved = localStorage.getItem('sidebarCollapsed');
    return saved ? JSON.parse(saved) : false;
  });
  const [isSidebarHidden, setIsSidebarHidden] = useState<boolean>(() => {
    const saved = localStorage.getItem('sidebarHidden');
    return saved ? JSON.parse(saved) : false;
  });
  const { subscribe, unsubscribe } = useEventBus();
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

  const handleOpenChat = () => {
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    subscribe('chat:open', () => {
      handleOpenChat();
    });

    return () => {
      unsubscribe('chat:open', () => {
        handleOpenChat();
      });
    };
  }, [subscribe, unsubscribe]);

  const handleContactSelect = (contact: Contact) => {
    setSelectedContact(contact);
  };

  // Update theme colors when theme changes
  useEffect(() => {
    const backgroundColor = isDark ? THEME_COLORS.dark.background : THEME_COLORS.light.background;
    document.body.style.backgroundColor = backgroundColor;

    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (themeColorMeta) {
      themeColorMeta.setAttribute('content', backgroundColor);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = backgroundColor;
      document.head.appendChild(meta);
    }
  }, [isDark]);

  useEffect(() => {
    setIsAuthenticated(checkAuth());

    if (checkAuth()) {
      const shouldAutoNavigate = localStorage.getItem('shouldAutoNavigate') === 'true';
      const lastLocation = localStorage.getItem('lastLocation');

      if (shouldAutoNavigate && lastLocation && lastLocation !== location.pathname + location.search) {
        const attemptNavigation = async () => {
          try {
            setLoadingAttempts(prev => prev + 1);
            await navigate(lastLocation);
            setLoadingAttempts(0);
            localStorage.removeItem('shouldAutoNavigate');
          } catch (error) {
            console.error('Navigation error:', error);
            if (loadingAttempts >= 2) {
              setHasLoadingError(true);
            }
          }
        };

        attemptNavigation();
      }
    }
  }, [loadingAttempts]);

  if (hasLoadingError) {
    useEffect(() => {
      const timer = setTimeout(() => {
        navigate('/');
      }, 10000);

      return () => clearTimeout(timer);
    }, [navigate]);

    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold mb-4">Failed to load page</h1>
        <p className="mb-4">We're having trouble loading your requested page.</p>
        <p className="mb-4">You will be automatically redirected to the home page in 10 seconds.</p>
        <a
          href="/"
          className="text-blue-500 hover:text-blue-700 underline"
        >
          Return to Home Now
        </a>
      </div>
    );
  }

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isSidebarCollapsed));
  }, [isSidebarCollapsed]);

  useEffect(() => {
    localStorage.setItem('lastLocation', location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    localStorage.setItem('sidebarHidden', JSON.stringify(isSidebarHidden));
  }, [isSidebarHidden]);

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={`h-screen flex ${THEME_CLASSES.background}`}>
      {!isSidebarHidden && (
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onCollapse={setIsSidebarCollapsed}
          onNavigate={() => {
            if (window.matchMedia(BREAKPOINTS.mobile).matches) {
              setIsSidebarHidden(true);
            }
          }}
        />
      )}

      <div className="flex-1 flex flex-col min-w-0 w-full max-w-full">
        <div className={`sticky top-0 z-40 ${THEME_CLASSES.border} ${THEME_CLASSES.background} w-full`}>
          <div className="w-full flex items-center">
            <Navbar
              onToggleModal={() => setIsSidebarHidden(!isSidebarHidden)}
              onToggleChat={isChatOpen ? handleCloseChat : handleOpenChat}
              currentContact={selectedContact}
            />
          </div>
        </div>

        <main className={`flex-1 ${THEME_CLASSES.background} overflow-hidden px-4 py-4 w-full max-w-full`}>
          <Notification />

          <div className={`transition-all duration-300 mr-0`}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/contacts" element={<Contacts />}>
                <Route path="my" element={<Contacts />} />
                <Route path="all" element={<Contacts />} />
                <Route index element={<Contacts />} />
              </Route>
              <Route
                path="/contacts/:id/:tab?"
                element={
                  <ContactPage
                    onContactSelect={handleContactSelect}
                  />
                }
              />
              <Route path="/chat" element={<Chat />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/accounts/:id" element={<AccountPage />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/pipelines" element={<Pipelines />} />
              <Route path="/pipelines/:id/:tab?" element={<Pipelines />} />
              <Route path="/events" element={<Events />} />
              <Route path="/notes/:tab?" element={<NotesPage />} />
            </Routes>
          </div>
        </main>
      </div>

      <div className={`fixed right-0 top-0 h-full w-full md:w-1/2 bg-white dark:bg-gray-800 border-l ${THEME_CLASSES.border} transform transition-transform duration-300 z-50 ${isChatOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <Chat onClose={handleCloseChat} />
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <EventBusProvider>
          <Router>
            <AppContent />
          </Router>
        </EventBusProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
