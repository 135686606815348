import { FC, useState, useEffect } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { Note, NOTE_TYPE_COLORS } from '../../models/Note';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { noteService } from '../../services/noteService';
import { useEventBus } from '../../context/EventBusContext';

interface OpportunityNotesProps {
  opportunity: Opportunity;
  onClose: () => void;
}

const OpportunityNotes: FC<OpportunityNotesProps> = ({ opportunity, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({
    title: '',
    content: '',
    type: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [formErrors, setFormErrors] = useState({
    title: false,
    content: false
  });
  const { publish } = useEventBus();
  const selectedNote = notes.find(note => note.noteId === selectedNoteId);

  const handleSummarize = () => {
    if (notes.length === 0) return;

    const prompt = `Summarize these notes into 3 key bullet points and suggest a to do list:
    \n${notes.map(n => `- ${n.title}: ${n.content}`).join('\n')}`;

    publish('chat:open', prompt);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const fetchedNotes = await noteService.getNotesByOpportunityId(opportunity.id);
        setNotes(fetchedNotes);
        if (fetchedNotes.length > 0) {
          setSelectedNoteId(fetchedNotes[0].noteId);
        }
      } catch (error) {
        console.error('Failed to fetch notes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, [opportunity.id]);

  useEffect(() => {
    if (selectedNoteId === -1) {
      setFormState({
        title: '',
        content: '',
        type: ''
      });
      setIsEditing(true);
    } else if (selectedNote) {
      setFormState({
        title: selectedNote.title,
        content: selectedNote.content,
        type: selectedNote.type || ''
      });
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [selectedNoteId, selectedNote]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
    setFormErrors(prev => ({
      ...prev,
      [name]: false
    }));
  };

  const validateForm = () => {
    const errors = {
      title: !formState.title.trim(),
      content: !formState.content.trim()
    };
    setFormErrors(errors);
    return !errors.title && !errors.content;
  };

  const handleSaveNote = async () => {
    if (!validateForm()) return;

    try {
      const noteData: Note = {
        ...formState,
        noteId: selectedNoteId && selectedNoteId !== -1 ? selectedNoteId : 0,
        title: formState.title,
        content: formState.content,
        type: formState.type || null,
        creatorId: 0, // TODO: Replace with actual user ID
        visibilityId: 1, // Default visibility
        statusId: null,
        priorityId: null,
        dueDate: null,
        startDate: null,
        assignedTo: null,
        completedAt: null,
        versionId: 1,
        modifiedBy: 0, // TODO: Replace with actual user ID
        modifiedAt: new Date(),
        createdAt: new Date(),
        priority: null,
        status: null
      };

      if (selectedNoteId === -1) {
        const newNote = await noteService.createNote(noteData);
        setNotes(prev => [newNote, ...prev]);
        setSelectedNoteId(newNote.noteId);
      } else if (selectedNote) {
        const updatedNote = await noteService.updateNote({
          ...selectedNote,
          ...noteData
        });
        setNotes(prev => prev.map(n =>
          n.noteId === updatedNote.noteId ? updatedNote : n
        ));
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save note:', error);
    }
  };

  const filteredNotes = notes.filter(note =>
    note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    note.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={`flex h-[calc(100%-3rem)] ${THEME_CLASSES.background} border border-gray-200 dark:border-gray-700`}>
      {/* Left Column - Notes List */}
      <div className="w-1/2 border-r border-gray-200 dark:border-gray-700 flex flex-col">
        {/* Search Bar */}
        <div className="p-3 border-b border-gray-200 dark:border-gray-700">
          <div className="relative">
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Search notes..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-1.5 pl-8 pr-3 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => {
                  setSelectedNoteId(-1);
                  setIsEditing(true);
                  setFormState({
                    title: '',
                    content: '',
                    type: ''
                  });
                  setFormErrors({
                    title: false,
                    content: false
                  });
                }}
                className="px-3 py-1.5 text-xs font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 whitespace-nowrap"
              >
                Add Note
              </button>
            </div>
            <svg
              className="absolute left-2.5 top-2 h-3 w-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
              />
            </svg>
          </div>
        </div>

        {/* Notes List */}
        <div className="flex-1 h-full overflow-y-auto">
          {loading ? (
            <div className="p-3 text-center text-xs text-gray-500">Loading notes...</div>
          ) : filteredNotes.length === 0 ? (
            <div className="p-3 text-center text-xs text-gray-500">No notes found</div>
          ) : (
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {filteredNotes.map(note => (
                <div
                  key={note.noteId}
                  className={`p-3 cursor-pointer ${selectedNoteId === note.noteId
                    ? 'bg-blue-50 dark:bg-blue-900/20'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-800'
                    }`}
                  onClick={() => setSelectedNoteId(note.noteId)}
                >
                  <div className="flex items-center justify-between mb-1">
                    <span className="text-xs font-medium text-gray-900 dark:text-white truncate">
                      {note.title}
                    </span>
                    <span className="text-xs text-gray-500 whitespace-nowrap ml-2">
                      {new Date(note.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-gray-400 line-clamp-2">
                    {note.content}
                  </p>
                  {note.type && (
                    <span className={`inline-block px-2 py-0.5 mt-1.5 text-xs font-medium rounded-full ${NOTE_TYPE_COLORS[note.type as keyof typeof NOTE_TYPE_COLORS]}`}>
                      {note.type}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Right Column */}
      <div className="flex-1 flex flex-col overflow-y-auto">

        {/* Notes Summary */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <div className="grid grid-cols-4 gap-4 text-xs mb-2">
            <div className="space-y-1">
              <div className="text-gray-500 dark:text-gray-400">Total Notes</div>
              <div className="font-medium text-gray-900 dark:text-white">
                {notes.length}
              </div>
            </div>
            {notes.length > 0 && (
              <>
                <div className="space-y-1">
                  <div className="text-gray-500 dark:text-gray-400">Last Modified At</div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {new Date(Math.max(...notes.map(n => new Date(n.modifiedAt).getTime()))).toLocaleDateString()}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="text-gray-500 dark:text-gray-400">Last Modified By</div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {notes.find(n =>
                      new Date(n.modifiedAt).getTime() ===
                      Math.max(...notes.map(n => new Date(n.modifiedAt).getTime()))
                    )?.modifiedBy || 'N/A'}
                  </div>
                </div>
              </>
            )}
            {notes.length === 0 && (
              <>
                <div className="space-y-1">
                  <div className="text-gray-500 dark:text-gray-400">Last Modified At</div>
                  <div className="font-medium text-gray-900 dark:text-white">N/A</div>
                </div>
                <div className="space-y-1">
                  <div className="text-gray-500 dark:text-gray-400">Last Modified By</div>
                  <div className="font-medium text-gray-900 dark:text-white">N/A</div>
                </div>
              </>
            )}

            {notes.length > 0 && (
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleSummarize}
                  className="flex items-center gap-2 px-3 py-2 text-xs font-medium text-white rounded-md  bg-orange-600 hover:bg-orange-700 dark:bg-orange-900 dark:hover:bg-orange-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
                  </svg>

                </button>
              </div>
            )}

          </div>


        </div>

        {/* Note Details */}

        {selectedNote || isEditing ? (
          <>

            {/* Note Edit Form */}
            <div className="p-4 border-t border-gray-200 dark:border-gray-700">
              <div className="space-y-4">
                <div>
                  <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formState.title}
                    onChange={handleFormChange}
                    className={`w-full px-3 py-1.5 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 ${formErrors.title ? 'border-red-500' : ''
                      }`}
                    placeholder="Note title"
                  />
                  {formErrors.title && (
                    <p className="text-xs text-red-500 mt-1">Title is required</p>
                  )}
                </div>
                <div>
                  <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Content
                  </label>
                  <textarea
                    rows={20}
                    name="content"
                    value={formState.content}
                    onChange={handleFormChange}
                    className={`w-full px-3 py-1.5 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 ${formErrors.content ? 'border-red-500' : ''
                      }`}
                    placeholder="Note content"
                  />
                  {formErrors.content && (
                    <p className="text-xs text-red-500 mt-1">Content is required</p>
                  )}
                </div>
                <div>
                  <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Type
                  </label>
                  <select
                    name="type"
                    value={formState.type}
                    onChange={handleFormChange}
                    className="w-full px-3 py-1.5 text-xs border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select type</option>
                    {Object.keys(NOTE_TYPE_COLORS).map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="p-3 border-t border-gray-200 dark:border-gray-700">
              <div className="flex justify-end space-x-2">
                <button
                  onClick={onClose}
                  className="px-3 py-1.5 text-xs font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveNote}
                  className="px-3 py-1.5 text-xs font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center text-xs text-gray-500">
            {loading ? 'Loading notes...' : 'Select a note to view details'}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunityNotes;
