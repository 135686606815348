import { Lead } from '../models/Lead';
import { apiService } from './apiService';

const LEADS_ENDPOINT = '/api/data/leads';

export async function getLeads(): Promise<Lead[]> {
  return apiService<Lead[]>(LEADS_ENDPOINT);
}

export async function getLeadById(id: number): Promise<Lead> {
  return apiService<Lead>(`${LEADS_ENDPOINT}/${id}`);
}

export const leadService = {
  getLeads,
  getLeadById
};
