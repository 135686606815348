import React, { useState, useEffect, useRef } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { contactService } from '../../services/contactService';
import { noteService } from '../../services/noteService';
import { Contact } from '../../models/Contact';
import { NOTE_TYPES } from '../../models/Note';
import { accountService } from '../../services/accountService';
import { opportunityService } from '../../services/opportunityService';
import { THEME_CLASSES } from '../../constants/themeConstants';
import NoteEditor from './NoteEditor';

type ObjectType = 'account' | 'contact' | 'opportunity';

interface NewNoteProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  initialContact?: Contact;
}

interface SearchResult {
  id: number;
  displayName: string;
}

type NoteType = typeof NOTE_TYPES[number];

interface FormState {
  title: string;
  content: string;
  type: NoteType;
  objectIds: string[];
  objectType: ObjectType;
}

const NewNote: React.FC<NewNoteProps> = ({ isModalOpen, toggleModal, initialContact }) => {
  const [selectedObjectType, setSelectedObjectType] = useState<ObjectType>('contact');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [selectedItems, setSelectedItems] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const [form, setForm] = useState<FormState>({
    title: '',
    content: '',
    type: 'Note',
    objectIds: [],
    objectType: 'contact'
  });

  useEffect(() => {
    if (isModalOpen && dialogRef.current) {
      dialogRef.current.showModal();
    } else if (dialogRef.current) {
      dialogRef.current.close();
    }
  }, [isModalOpen]);

  useEffect(() => {
    setSelectedItems([]);
    setSearchResults([]);
    setIsDropdownOpen(false);
    setForm(prev => ({
      ...prev,
      objectType: selectedObjectType,
      objectIds: []
    }));
  }, [selectedObjectType]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const searchObjects = async () => {
      if (debouncedSearchTerm.length >= 2) {
        setIsSearching(true);
        try {
          let results: SearchResult[] = [];
          switch (selectedObjectType) {
            case 'contact':
              const contacts = await contactService.searchContacts(debouncedSearchTerm);
              results = contacts.map(c => ({ id: c.id, displayName: c.displayName }));
              break;
            case 'account':
              const accounts = await accountService.getAccounts();
              results = accounts
                .filter(a => a.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
                .map(a => ({ id: a.id, displayName: a.name }));
              break;
            case 'opportunity':
              const opportunity = await opportunityService.getOpportunity(parseInt(debouncedSearchTerm));
              if (opportunity) {
                results = [{ id: opportunity.id, displayName: opportunity.title }];
              }
              break;
          }
          setSearchResults(results);
          setIsDropdownOpen(true);
        } catch (error) {
          console.error(`Failed to search ${selectedObjectType}s:`, error);
          setSearchResults([]);
        } finally {
          setIsSearching(false);
        }
      } else {
        setSearchResults([]);
        setIsDropdownOpen(false);
      }
    };

    void searchObjects();
  }, [debouncedSearchTerm, selectedObjectType]);

  const handleSearchFocus = () => {
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
    }
  };

  const handleItemSelect = (item: SearchResult): void => {
    if (!selectedItems.some(i => i.id === item.id)) {
      setSelectedItems(prev => [...prev, item]);
      setForm(prev => ({
        ...prev,
        objectIds: [...prev.objectIds, item.id.toString()],
        objectType: selectedObjectType
      }));
    }
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  const handleRemoveItem = (itemId: number): void => {
    setSelectedItems(prev => prev.filter(i => i.id !== itemId));
    setForm(prev => ({
      ...prev,
      objectIds: prev.objectIds.filter(id => id !== itemId.toString())
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    if (form.objectIds.length === 0) {
      alert(`Please select at least one ${form.objectType}`);
      return;
    }

    try {
      // Only include email list if object type is contact
      const emailList = form.objectType === 'contact'
        ? selectedItems
          .map(item => (item as Contact).email)
          .filter((email: string | undefined) => email)
          .join(',')
        : '';

      const noteData = {
        objectIds: form.objectIds,
        objectType: form.objectType,
        title: form.title,
        content: form.content,
        creatorId: 3,
        emailList: emailList,
        type: form.type,
      };

      const newNote = await noteService.createNote(noteData);
      console.log(newNote);
      toggleModal();
    } catch (error) {
      console.error('Failed to create note:', error);
      alert('Failed to create note. Please try again.');
    }
  };

  return (
    <dialog
      ref={dialogRef}
      className="fixed inset-0 z-50 bg-transparent"
      onClose={toggleModal}
    >
      <div className="fixed inset-0 bg-black/50" />
      <div className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto ${THEME_CLASSES.background}`}>
        <div className={`flex justify-between items-center mb-2 ${THEME_CLASSES.note.card.background.gray} -m-8 rounded-t-lg`}>
          <h3 className={`text-lg font-semibold ${THEME_CLASSES.text.primary} pl-4 pt-2 my-auto`}>
            Add New Note
          </h3>
          <button
            type="button"
            onClick={() => dialogRef.current?.close()}
            className={`${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text} ${THEME_CLASSES.hover.background} rounded-full mr-4`}
            aria-label="Close"
          >
            ×
          </button>
        </div>
        <div className="flex-1 flex flex-col">
          {/* Object Type Tabs and Search */}
          <div className={`p-6 ${THEME_CLASSES.border} border-b`}>
            <div className="flex items-center">
              <div className="flex justify-center space-x-1">
                {(['account', 'contact', 'opportunity'] as ObjectType[]).map((type) => (
                  <button
                    key={type}
                    type="button"
                    onClick={() => setSelectedObjectType(type)}
                    className={`px-4 py-2 text-xs font-medium rounded-md ${selectedObjectType === type
                      ? `${THEME_CLASSES.button.primary} shadow-sm`
                      : `${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.text}`
                      }`}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </button>
                ))}
              </div>
              <div className="flex-1 ml-4 relative">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={handleSearchFocus}
                  className={`block w-full ${THEME_CLASSES.input.field} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} ${THEME_CLASSES.input.base}`}
                  placeholder={`Search ${selectedObjectType}s (minimum 2 characters)...`}
                />
                {isSearching && (
                  <div className="absolute right-3 top-[50%] -translate-y-1/2">
                    <svg className={`animate-spin h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                )}
                {/* Search Results Dropdown */}
                {isDropdownOpen && searchResults.length > 0 && (
                  <div ref={searchRef} className={`absolute z-50 w-full left-0 mt-1 rounded-md shadow-lg max-h-60 overflow-auto ${THEME_CLASSES.input.background} ${THEME_CLASSES.border}`}>
                    {searchResults.map(result => (
                      <div
                        key={result.id}
                        onClick={() => handleItemSelect(result)}
                        className={`px-4 py-2 cursor-pointer text-xs ${THEME_CLASSES.text.primary} ${THEME_CLASSES.hover.background}`}
                      >
                        {result.displayName}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/* Selected Items List */}
            <div className="flex flex-wrap gap-2 mt-4">
              {selectedItems.map(item => (
                <span
                  key={item.id}
                  className={`px-2 py-1 rounded-full text-xs flex items-center ${THEME_CLASSES.button.primary}`}
                >
                  {item.displayName}
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(item.id)}
                    className={`ml-1 ${THEME_CLASSES.text.primary} ${THEME_CLASSES.hover.text}`}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
          <NoteEditor
            content={form.content}
            type={form.type}
            onContentChange={(content) => setForm(prev => ({ ...prev, content }))}
            onTypeChange={(type) => setForm(prev => ({ ...prev, type }))}
            onSubmit={handleSubmit}
            onCancel={() => dialogRef.current?.close()}
          />
        </div>
      </div>
    </dialog>
  );
};

export default NewNote;
