import { Contact } from '../models/Contact';
import { apiService } from './apiService';
import { getUserData } from './authService';

interface PaginationParams {
  page: number;
  pageSize: number;
  search?: string;
  sortBy?: keyof Contact;
  sortDirection?: 'asc' | 'desc';
  status?: string;
  companies?: string[];
}

const USERS_ENDPOINT = '/api/data/users';
const CONTACTS_ENDPOINT = '/api/data/contacts';

export async function getAllContactsPaginated(
  params: PaginationParams
): Promise<Contact[]> {
  const { page, pageSize, search, sortBy, sortDirection, status, companies } = params;

  const queryParams = new URLSearchParams({
    'page': page.toString(),
    'pageSize': pageSize.toString(),
    ...(search ? { 'search': search } : {}),
    ...(sortBy ? { 'sortField': `${sortBy} ${sortDirection || 'asc'}` } : {}),
    ...(status ? { 'status': status } : {}),
    ...(companies ? { 'companies': companies.join(',') } : {})
  });

  return apiService<Contact[]>(`${CONTACTS_ENDPOINT}?${queryParams}`);
}

export async function getContactById(id: number): Promise<Contact> {
  return apiService<Contact>(`${CONTACTS_ENDPOINT}/${id}`);
}

export async function searchContacts(query: string): Promise<Contact[]> {
  if (!query || query.length < 2) return [];
  return apiService<Contact[]>(`${CONTACTS_ENDPOINT}/search?q=${encodeURIComponent(query)}`);
}

export async function getContactsCount(): Promise<number> {
  const response = await apiService<{ count: number }>('/api/data/contacts/count');
  return response.count;
}


export async function getMyContacts(): Promise<Contact[]> {
  const user = getUserData()?.email;
  if (!user) return [];
  return apiService<Contact[]>(`${USERS_ENDPOINT}/${encodeURIComponent(user)}/contacts`);
}

export async function addMyContact(email: string): Promise<void> {
  const user = getUserData()?.email;
  if (!user) return;
  await apiService<Contact[]>(`${USERS_ENDPOINT}/${encodeURIComponent(user)}/contacts`, {
    method: 'post',
    body: JSON.stringify({ email }),
  });
}

export async function deleteMyContact(id: number): Promise<void> {
  const user = getUserData()?.email;
  if (!user) return;
  await apiService<Contact[]>(`${USERS_ENDPOINT}/${encodeURIComponent(user)}/contacts/${id}`, {
    method: 'delete'
  });
}

// Maintain backward compatibility
export const deletMyContact = deleteMyContact;

export const contactService = {
  getAllContactsPaginated,
  getContactById,
  searchContacts,
  getContactsCount,
  getMyContacts,
  addMyContact,
  deleteMyContact
};
