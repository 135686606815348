import { Account } from '../../models/Account';

interface AccountDetailsProps {
  account: Account;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };


  const formatNumber = (value: number | null | undefined) => {
    return value?.toLocaleString() || '-';
  };

  const sections = [
    {
      title: 'Basic Information',
      fields: [
        { label: 'Name', value: account.companyName },
        { label: 'Franchise Group', value: account.franchiseGroupName },
        { label: 'Open Date', value: formatDate(account.openDate) },
        { label: 'Contract End Date', value: formatDate(account.contractEndDate) },
      ]
    },
    {
      title: 'Location',
      fields: [
        { label: 'Street Address', value: account.streetAddress },
        { label: 'City', value: account.city },
        { label: 'Province', value: account.province },
        { label: 'Mailing Address', value: account.mailingAddress },
        { label: 'Location Count', value: formatNumber(account.locationCount) },
      ]
    },
    {
      title: 'Staff Counts',
      fields: [
        { label: 'Total Agents', value: formatNumber(account.activeAgentCount) },
        { label: 'Broker Owners', value: formatNumber(account.activeBrokerOwnerCount) },
        { label: 'Broker Managers', value: formatNumber(account.activeBrokerManagerCount) },
        { label: 'Administration', value: formatNumber(account.activeAdministrationCount) },
        { label: 'Finance Staff', value: formatNumber(account.activeFinanceStaffCount) },
        { label: 'Licensed Assistants', value: formatNumber(account.activeLicensedAssistantCount) },
        { label: 'Unlicensed Assistants', value: formatNumber(account.activeUnlicensedAssistantCount) },
        { label: 'Total Employees', value: formatNumber(account.activeEmployeeCount) },
      ]
    },
    {
      title: 'Status Counts',
      fields: [
        { label: 'Paying Members', value: formatNumber(account.activePayingCount) },
        { label: 'Non-Paying Members', value: formatNumber(account.activeNonPayingCount) },
      ]
    }
  ];

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700 space-y-8">
      {sections.map(section => (
        <div key={section.title}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            {section.title}
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {section.fields.map(field => (
              <div
                key={field.label}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 p-4 rounded-lg transition-colors"
              >
                <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                  {field.label}
                </div>
                <div className="text-sm font-semibold text-gray-900 dark:text-gray-100">
                  {field.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountDetails;
