import { FC, useState, useEffect } from 'react';
import Spinner from '../components/common/Spinner';
import { THEME_CLASSES } from '../constants/themeConstants';
import { useParams, useNavigate } from 'react-router-dom';
import { contactService } from '../services/contactService';
import { Contact } from '../models/Contact';
import { getContactPhotoUrl } from '../utils/avatarUtils';
import { noteService } from '../services/noteService';
import { getNotesSummaryPrompt } from '../utils/summaryUtils';
import { useNotification } from '../context/NotificationContext';
import ContactDetails from '../components/contact/ContactDetails';
import TimelineEvents from '../components/contact/TimelineEvents';
import NoteList from '../components/note/NoteList';
import NotesBoard from '../components/note/NotesBoard';
import NotesTimeline from '../components/note/NotesGantt';
import { NoteComponentProps } from '../models/Note';
import { useEventBus } from '../context/EventBusContext';

interface SectionStates {
  contactInfo: boolean;
  timelineEvents: boolean;
  contactDetails: boolean;
  activitySummary: boolean;
  notesHistory: boolean;
}

interface ContactPageProps {
  onContactSelect: (contact: Contact) => void;
}

const ContactPage: FC<ContactPageProps> = ({ onContactSelect }) => {
  const { showNotification } = useNotification();
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const navigate = useNavigate();
  const [contact, setContact] = useState<Contact | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<'Overview' | 'Timeline' | 'Notes' | 'Board' | 'Gantt'>(
    tab === 'timeline' ? 'Timeline' :
      tab === 'notes' ? 'Notes' :
        tab === 'board' ? 'Board' :
          tab === 'gantt' ? 'Gantt' : 'Overview'
  );
  const [sectionStates, setSectionStates] = useState<SectionStates>({
    contactInfo: false,
    timelineEvents: false,
    contactDetails: false,
    activitySummary: false,
    notesHistory: false
  });

  const { publish } = useEventBus();

  // Load section states from localStorage on mount
  useEffect(() => {
    const savedStates = localStorage.getItem('contactPageSectionStates');
    if (savedStates) {
      setSectionStates(JSON.parse(savedStates));
    }
  }, []);

  // Save section states to localStorage when they change
  useEffect(() => {
    localStorage.setItem('contactPageSectionStates', JSON.stringify(sectionStates));
  }, [sectionStates]);

  // Effect for fetching contact data
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      try {
        const contactId = parseInt(id);
        const contactData = await contactService.getContactById(contactId);
        if (contactData) {
          setContact(contactData);
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    void fetchData();
  }, [id]);

  // Separate effect for updating navbar
  useEffect(() => {
    if (contact) {
      onContactSelect(contact);
    }
  }, [contact]);

  const handleSummarize = async () => {
    if (!contact) return;
    const notes = await noteService.getNotesByContactId(contact.id);
    if(!notes || !notes.length) return;
    const prompt = getNotesSummaryPrompt(notes);
    publish('chat:open', prompt);
  };

  if (isLoading) return (
    <div className="fixed inset-0 flex items-center justify-center">
      <Spinner />
    </div>
  );

  if (!contact) return <div></div>;

  return (
    <div className='h-full flex flex-col'>
      <div className={`${THEME_CLASSES.background} border-b ${THEME_CLASSES.border}`}>
        <div className="mx-auto">
          <div className="px-4">
            <div className="flex flex-row items-start gap-4 mb-6">
              <div className="w-20 h-20 rounded-lg border-2 ${THEME_CLASSES.border} overflow-hidden flex-shrink-0">
                <img
                  src={getContactPhotoUrl(contact)}
                  alt={`${contact.firstName} ${contact.lastName}`}
                  className="w-full h-full object-cover bg-gray-300 dark:bg-gray-600"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '';
                    target.className = 'w-full h-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center';
                  }}
                />
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4">
                  <div className="flex-1 flex flex-col md:flex-row gap-2 md:gap-4 items-start md:items-center">
                    <h1 className={`text-lg font-semibold my-auto ${THEME_CLASSES.text.primary}`}>
                      {contact.firstName} {contact.lastName}
                    </h1>
                    <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                      {contact.companyName}
                    </div>
                  </div>
                  <div className="flex gap-2 md:gap-4">
                    <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                      {contact.email}
                    </div>
                    <div className={`text-sm ${THEME_CLASSES.text.secondary} truncate`}>
                      {contact.phone}
                    </div>
                  </div>
                </div>
                <nav className="flex flex-wrap gap-2 mt-4 -ml-24 md:ml-0" aria-label="Tabs">
                  <button
                    onClick={() => {
                      setActiveTab('Overview');
                      navigate(`/contacts/${id}/overview`);
                    }}
                    className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'Overview'
                      ? `${THEME_CLASSES.button.primary} font-bold`
                      : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                  >
                    Overview
                  </button>
                  <button
                    onClick={() => {
                      setActiveTab('Timeline');
                      navigate(`/contacts/${id}/timeline`);
                    }}
                    className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'Timeline'
                      ? `${THEME_CLASSES.button.primary} font-bold`
                      : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                  >
                    Timeline
                  </button>
                  <button
                    onClick={() => {
                      setActiveTab('Notes');
                      navigate(`/contacts/${id}/notes`);
                    }}
                    className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'Notes'
                      ? `${THEME_CLASSES.button.primary} font-bold`
                      : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                  >
                    Notes
                  </button>
                  <button
                    onClick={() => {
                      setActiveTab('Board');
                      navigate(`/contacts/${id}/board`);
                    }}
                    className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'Board'
                      ? `${THEME_CLASSES.button.primary} font-bold`
                      : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                  >
                    Board
                  </button>
                  <button
                    onClick={() => {
                      setActiveTab('Gantt');
                      navigate(`/contacts/${id}/gantt`);
                    }}
                    className={`rounded-full py-1 px-3 text-xs md:text-sm font-medium ${activeTab === 'Gantt'
                      ? `${THEME_CLASSES.button.primary} font-bold`
                      : `${THEME_CLASSES.button.secondary} ${THEME_CLASSES.hover.background}`
                      }`}
                  >
                    Gantt
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        await contactService.addMyContact(contact.email);
                        showNotification(`${contact.firstName} ${contact.lastName} saved to your contact list!`, 'success');
                      } catch (error) {
                        console.error('Error saving contact:', error);
                        showNotification('Failed to save contact', 'error');
                      }
                    }}
                    className={`ml-4 rounded ${THEME_CLASSES.hover.background}`}
                    title="Save to your contacts"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${THEME_CLASSES.text.primary}`} viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                    </svg>
                  </button>

                  <button
                    onClick={handleSummarize}
                    className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                    title="Ask AI about this contact"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </button>

                  <button
                    onClick={() => {
                      const eventTitle = `RE: ${contact.firstName} ${contact.lastName}`;
                      const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(eventTitle)}`;
                      window.open(calendarUrl, '_blank');
                    }}
                    className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                    title="Add to Google Calendar"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </button>

                  <button
                    onClick={() => {
                      const subject = `RE: ${contact.firstName} ${contact.lastName}`;
                      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
                      window.open(gmailUrl, '_blank');
                    }}
                    className={`p-2 rounded-md ${THEME_CLASSES.text.secondary} ${THEME_CLASSES.hover.background}`}
                    title="Compose Gmail"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>

            <div className={`h-px w-full ${THEME_CLASSES.border} mt-4`}></div>
          </div>
        </div>
      </div>
      <div className="flex-1 pt-2 overflow-hidden">
        {activeTab === 'Overview' && <ContactDetails contact={contact} />}
        {activeTab === 'Timeline' && <TimelineEvents contact={contact} />}
        {activeTab === 'Notes' && (
          <NoteList {...{ type: 'contact' as const, id: parseInt(id || '0') } as NoteComponentProps} />
        )}
        {activeTab === 'Board' && (
          <NotesBoard type="contact" id={parseInt(id || '0')} />
        )}
        {activeTab === 'Gantt' && (
          <NotesTimeline {...{ type: 'contact' as const, id: parseInt(id || '0') } as NoteComponentProps} />
        )}
      </div>
    </div>
  );
}

export default ContactPage;
