import { FC } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { THEME_CLASSES } from '../../constants/themeConstants';

export const OPPORTUNITY_FIELDS = [
  { name: 'title', type: 'string', required: true },
  { name: 'value', type: 'number', required: true },
  { name: 'status', type: 'string', required: true },
  { name: 'potentialAcquirers', type: 'string', required: true },
  { name: 'markets', type: 'string', required: true },
  { name: 'province', type: 'string', required: true },
  { name: 'salesType', type: 'string', required: true },
  { name: 'numberOfAgents', type: 'number', required: true },
  { name: 'probability', type: 'number', required: true },
  { name: 'lastOutreach', type: 'Date', required: true },
  { name: 'nextOutreach', type: 'Date', required: true },
  { name: 'origination', type: 'string', required: true },
  { name: 'targetCloseDate', type: 'Date', required: true },
  { name: 'disclosureDate', type: 'Date', required: true },
  { name: 'streetAddress', type: 'string', required: true },
  { name: 'city', type: 'string', required: true },
  { name: 'postalCode', type: 'string', required: true },
] as const;


interface OpportunityFormProps {
  initialData?: Opportunity;
  onSubmit: (opportunity: Opportunity) => void;
  onCancel: () => void;
}

const OpportunityForm: FC<OpportunityFormProps> = ({
  initialData,
  onSubmit,
  onCancel
}) => {

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Implement form submission
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        {OPPORTUNITY_FIELDS.map((field) => (
          <div key={field.name} className="space-y-1">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {field.name.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
            </label>
            <input
              type={field.type === 'Date' ? 'date' : field.type.toLowerCase()}
              defaultValue={
                field.type === 'Date'
                  ? initialData?.[field.name as keyof Opportunity]
                    ? new Date(initialData[field.name as keyof Opportunity] as string)
                        .toISOString()
                        .split('T')[0]
                    : ''
                  : String(initialData?.[field.name as keyof Opportunity] ?? '')
              }
              className={`w-full border ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} rounded-md px-3 py-1 text-xs`}
              required={field.required}
            />
          </div>
        ))}
      </div>

      {/* <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Description
        </label>
        <textarea
          defaultValue={opportunity?.description}
          className={`w-full border ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} rounded-md px-3 py-2 text-xs`}
          rows={4}
        />
      </div> */}

    </form>
  );
};

export default OpportunityForm;
