import { FC, useState, useEffect, useRef } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';

const PIXELS_PER_DAY = 32;
import { format } from 'date-fns';
import { noteService } from '../../services/noteService';
import { Note, NOTE_TYPES, NOTE_TYPE_COLORS, NoteComponentProps } from '../../models/Note';

interface NoteCardProps {
  note: Note;
  color: string;
}

const NoteCard: FC<NoteCardProps> = ({ note, color }) => {
  const bgColorClass = THEME_CLASSES.note.card.background[
    color.replace('bg-', '').split('-')[0] as keyof typeof THEME_CLASSES.note.card.background
  ];

  return (
    <div
      className={`flex flex-col p-2 rounded cursor-pointer min-w-[200px] h-full ${bgColorClass}`}
    >
      <div className="font-medium text-sm dark:text-gray-100">{note.title}</div>
      {note.content && (
        <div className="text-xs mt-1 dark:text-gray-300">{note.content}</div>
      )}
      {(note.startDate || note.dueDate) && (
        <div className="text-[0.7rem] mt-3 mb-1">
          {note.startDate && format(new Date(note.startDate), 'MMM d')}
          {note.dueDate && ` - ${format(new Date(note.dueDate), 'MMM d')}`}
        </div>
      )}
    </div>
  );
};

type NoteType = keyof typeof NOTE_TYPE_COLORS;
type NotesByType = Record<Exclude<NoteType, 'All'>, Note[]>;

const NotesTimeline: FC<NoteComponentProps> = ({ type, id }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const labelRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const cardRefs = useRef<Record<string, HTMLDivElement | null>>({});

  // Match heights of labels and cards
  useEffect(() => {
    const updateHeights = () => {
      Object.entries(labelRefs.current).forEach(([noteType, labelRef]) => {
        const cardRef = cardRefs.current[noteType];
        if (labelRef && cardRef) {
          const cardHeight = cardRef.offsetHeight;
          labelRef.style.height = `${cardHeight}px`;
        }
      });
    };

    // Initial height update
    updateHeights();

    // Update heights on window resize
    window.addEventListener('resize', updateHeights);
    return () => window.removeEventListener('resize', updateHeights);
  }, [notes]);

  useEffect(() => {
    const fetchNotes = async () => {
      const notes = await noteService.getNotesByType(type, id);
      setNotes(notes);
    };
    fetchNotes();
  }, [type, id]);

  // Get date range for all notes
  const dates = notes.reduce((acc, note) => {
    const startDate = note.startDate ? new Date(note.startDate) : null;
    const dueDate = note.dueDate ? new Date(note.dueDate) : null;

    if (startDate) acc.push(startDate);
    if (dueDate) acc.push(dueDate);
    return acc;
  }, [] as Date[]);

  const minDate = dates.length > 0 ? new Date(Math.min(...dates.map(d => d.getTime())) - 3 * 24 * 60 * 60 * 1000) : new Date();
  const maxDate = dates.length > 0 ? new Date(Math.max(...dates.map(d => d.getTime())) + 3 * 24 * 60 * 60 * 1000) : new Date();

  // Generate intermediate dates
  const generateDateMarkers = () => {
    const markers: Date[] = [];
    const currentDate = new Date(minDate);

    while (currentDate <= maxDate) {
      markers.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return markers;
  };

  const dateMarkers = generateDateMarkers();

  // Calculate position and width for each note bar
  const calculateDaysBetween = (date: Date) => {
    return (date.getTime() - minDate.getTime()) / (1000 * 60 * 60 * 24);
  };

  const calculatePosition = (start: Date, end: Date) => {
    const startDays = calculateDaysBetween(start);
    const duration = calculateDaysBetween(end) - startDays;

    const left = startDays * PIXELS_PER_DAY;
    const width = duration * PIXELS_PER_DAY;

    return {
      left: `${left}px`,
      width: `${width}px`
    };
  };

  const calculateDatePosition = (date: Date) => {
    const days = calculateDaysBetween(date);
    return days * PIXELS_PER_DAY;
  };


  // Group notes by type
  const notesByType = NOTE_TYPES.reduce((acc, type) => {
    if (type !== 'All') {
      acc[type as Exclude<NoteType, 'All'>] = notes.filter(note => note.type === type);
    }
    return acc;
  }, {} as NotesByType);

  return (
    <div className="w-full h-full flex flex-col bg-white dark:bg-gray-900 rounded-lg shadow-sm">
      <div className="flex flex-1 min-h-0 relative overflow-y-auto" style={{ minHeight: '400px' }}>
        <div className="sticky left-0 z-20 bg-gray-100 dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700">
          <div className="flex flex-col pt-8">
            {Object.entries(notesByType).map(([noteType]) => (
              <div
                key={noteType}
                ref={el => labelRefs.current[noteType] = el}
                className="min-h-[100px] mb-6 flex items-center px-4"
                data-id={`note-type-${noteType}`}
              >
                <div className="text-sm font-medium text-gray-700 dark:text-gray-200">{noteType}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative h-full">

          {/* Today */}
          {(new Date() >= minDate && new Date() <= maxDate) && (
            <div
              className="absolute w-px bg-red-300"
              style={{
                left: `${calculateDatePosition(new Date()) + 16}px`,
                marginTop: '20px',
                height: 'calc(100%)'
              }}
            />
          )}

          {/* Date marks */}
          <div className="flex gap-0 mb-10 relative after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-[-1px] after:h-[1px] after:bg-gradient-to-r after:from-transparent after:via-gray-200 after:to-transparent">
            {dateMarkers.map((date, index) => {
              const leftPosition = calculateDatePosition(date);

              return (
                <div
                  key={index}
                  className={`
                    absolute py-1 w-8
                    ${date.getDate() === new Date().getDate() &&
                      date.getMonth() === new Date().getMonth() &&
                      date.getFullYear() === new Date().getFullYear()
                      ? THEME_CLASSES.note.timeline.dateMark.today
                      : [0, 6].includes(date.getDay())
                        ? THEME_CLASSES.note.timeline.dateMark.weekend
                        : THEME_CLASSES.note.timeline.dateMark.weekday
                    }
                    ${date.getDay() === 0 ? THEME_CLASSES.note.timeline.dateMark.border : ''}
                  `}
                  style={{
                    left: `${leftPosition}px`
                  }}
                >
                  <div className={`text-xs text-center relative ${date < new Date() ? THEME_CLASSES.text.pastDate : 'dark:text-gray-100'}`}>
                    {date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}
                  </div>
                </div>
              );
            })}

          </div>

          {Object.entries(notesByType).map(([noteType, typeNotes]) => (
            <div
              key={noteType}
              ref={el => cardRefs.current[noteType] = el}
              className="flex flex-row mb-6 border-b border-gray-200 dark:border-gray-700 pb-4 min-h-[100px]"
              data-id={`note-cards-${noteType}`} style={{ width: `${dateMarkers.length * PIXELS_PER_DAY}px` }}
            >
              {typeNotes.map((note) => {
                const start = note.startDate ? new Date(note.startDate) : new Date(note.createdAt);
                const end = note.dueDate ? new Date(note.dueDate) : new Date(start.getTime() + 24 * 60 * 60 * 1000);
                const position = calculatePosition(start, end);
                const color = NOTE_TYPE_COLORS[noteType as keyof typeof NOTE_TYPE_COLORS].split(' ')[0];

                return (
                  <div
                    key={note.noteId}
                    className="absolute"
                    style={{
                      ...position,

                    }}
                  >
                    <NoteCard
                      note={note}
                      color={color}
                    />
                  </div>
                );
              })}

            </div>
          ))}
        </div>


      </div>
    </div>
  );
};

export default NotesTimeline;
