import { FC, useEffect, useState, useMemo } from 'react';
import { THEME_CLASSES } from '../constants/themeConstants';
import { accountService } from '../services/accountService';
import { Account } from '../models/Account';
import AccountList from '../components/account/AccountList';
import Spinner from '../components/common/Spinner';

const FILTER_LABELS = {
  type1: 'RLP',
  type0: 'CRM',
  all: 'All'
};

const Accounts: FC = () => {
  const [accounts, setAccounts] = useState<Account[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem('accounts-search') || '';
  });
  const [accountFilter, setAccountFilter] = useState<'type1' | 'type0' | 'all'>(() => {
    const stored = localStorage.getItem('accounts-filter');
    // Default to 'all' if no stored value or invalid value
    return stored === 'type1' || stored === 'type0' || stored === 'all' 
      ? stored as 'type1' | 'type0' | 'all'
      : 'all';
  });

  // Save type filter to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('accounts-filter', accountFilter);
  }, [accountFilter]);

  const filteredAccounts = useMemo(() => {
    const trimmedQuery = searchQuery.trim();
    if (!accounts) return [];

    let filtered = accounts;

    // Apply type filter
    if (accountFilter !== 'all') {
      filtered = filtered.filter(account => 
        accountFilter === 'type1' ? account.type === 1 : account.type === 0
      );
    }

    // Apply search filter
    if (trimmedQuery) {
      const keywords = trimmedQuery
        .toLowerCase()
        .split(' ')
        .map(k => k.trim())
        .filter(k => k.length > 0);

      filtered = filtered.filter(account => {
        if (!account) return false;

        const searchableFields = [
          account.name?.toString() || '',
          account.type?.toString() || '',
          account.companyId?.toString() || '',
          account.website || '',
          account.phone || '',
          account.address || '',
          account.city || '',
          account.province || ''
        ].map(field => field.toLowerCase());

        return keywords.every(keyword =>
          searchableFields.some(field => field.includes(keyword))
        );
      });
    }

    return filtered;
  }, [accounts, searchQuery, accountFilter]);

  // Save search to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('accounts-search', searchQuery);
  }, [searchQuery]);

  // Save type filter to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('accounts-filter', accountFilter);
  }, [accountFilter]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const data = await accountService.getAccounts();
        setAccounts(data || []);
        setError(null);
      } catch (err) {
        setError('Failed to load accounts');
        console.error('Error fetching accounts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  if (loading) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`p-6 ${THEME_CLASSES.text.primary}`}>
        <div className="flex items-center justify-center h-64">
          <div className="text-red-500">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col overflow-hidden'>
      <div className="flex items-center p-6 gap-4 flex-shrink-0">
        <h1 className={`text-xl font-semibold ${THEME_CLASSES.text.primary} my-auto`}>Accounts</h1>
        <div className="w-96 relative ml-auto">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search accounts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`w-full pl-11 pr-8 py-2 border rounded-md text-xs font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder}`}
          />
          {searchQuery && (
            <button
              onClick={() => setSearchQuery('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg className={`h-4 w-4 ${THEME_CLASSES.text.secondary} hover:${THEME_CLASSES.text.primary}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          )}
        </div>

        <div className="flex items-center space-x-2">
          <span className={`text-sm ${THEME_CLASSES.text.secondary}`}>
            {FILTER_LABELS[accountFilter]}
          </span>
          <button
            onClick={() => {
              setAccountFilter(current => 
                current === 'type1' ? 'type0' :
                current === 'type0' ? 'all' : 'type1'
              );
            }}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
              accountFilter === 'type1' ? 'bg-red-500' :
              accountFilter === 'type0' ? 'bg-blue-400' : 'bg-gray-200 dark:bg-gray-700'
            }`}
          >
            <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
              accountFilter === 'type1' ? 'translate-x-6' :
              accountFilter === 'type0' ? 'translate-x-3' : 'translate-x-1'
            }`} />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto max-h-[calc(100vh-150px)] w-full">
        <AccountList
          accounts={filteredAccounts || []}
          keywords={searchQuery
            .toLowerCase()
            .split(' ')
            .map(k => k.trim())
            .filter(k => k.length > 0)}
        />
      </div>
    </div>
  );
};

export default Accounts;
