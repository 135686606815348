import { createContext, useContext, useCallback } from 'react';

type EventHandler = (data?: any) => void;

interface EventBusContextType {
  subscribe: (event: string, callback: EventHandler) => void;
  unsubscribe: (event: string, callback: EventHandler) => void;
  publish: (event: string, data?: any) => void;
}

const EventBusContext = createContext<EventBusContextType>({
  subscribe: () => {},
  unsubscribe: () => {},
  publish: () => {},
});

export const EventBusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const listeners = new Map<string, EventHandler[]>();

  const subscribe = useCallback((event: string, callback: EventHandler) => {
    if (!listeners.has(event)) {
      listeners.set(event, []);
    }
    listeners.get(event)?.push(callback);
  }, []);

  const unsubscribe = useCallback((event: string, callback: EventHandler) => {
    const eventListeners = listeners.get(event);
    if (eventListeners) {
      listeners.set(
        event,
        eventListeners.filter((cb) => cb !== callback)
      );
    }
  }, []);

  const publish = useCallback((event: string, data?: any) => {
    const eventListeners = listeners.get(event);
    if (eventListeners) {
      eventListeners.forEach((callback) => callback(data));
    }
  }, []);

  return (
    <EventBusContext.Provider value={{ subscribe, unsubscribe, publish }}>
      {children}
    </EventBusContext.Provider>
  );
};

export const useEventBus = () => useContext(EventBusContext);
