import { apiService } from './apiService';
import { getUserData } from './authService';
import { NewsItem } from '../models/NewsItem';

const NEWS_ENDPOINT = '/api/data/news';

export async function getAllNews(): Promise<NewsItem[]> {
  return apiService<NewsItem[]>(NEWS_ENDPOINT);
}

export async function createNews(content: string, entityType: string, entityId: number, url: string = window.location.pathname): Promise<void> {
  await apiService<void>(NEWS_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      content,
      entityType,
      entityId,
      url,
      user: getUserData()?.name
    })
  });
}

export const newsService = {
  getAllNews,
  createNews
};
