import { useNotification } from '../../context/NotificationContext';
import { THEME_CLASSES } from '../../constants/themeConstants';

export const Notification = () => {
  const { notification, clearNotification } = useNotification();

  if (!notification) return null;

  const statusClass = notification.type === 'success' 
    ? THEME_CLASSES.status.success
    : notification.type === 'error' 
      ? THEME_CLASSES.status.error
      : THEME_CLASSES.status.default;

  return (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 p-4 rounded-lg shadow-lg ${statusClass} animate-slide-down-fade`}>
      <div className="flex items-center justify-between">
        <span>{notification.message}</span>
        <button 
          onClick={clearNotification}
          className="ml-4 p-1 rounded-full hover:bg-black/10 dark:hover:bg-white/10"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};
