export interface Note {
  noteId: number;
  title: string;
  creatorId: number;
  visibilityId: number;
  createdAt: Date;
  statusId: number | null;
  priorityId: number | null;
  dueDate: Date | null;
  startDate: Date | null;
  assignedTo: number | null;
  completedAt: Date | null;
  versionId: number;
  content: string;
  modifiedBy: number;
  modifiedAt: Date;
  priority: string | null;
  status: string | null;
  type: string | null;
}

export const NOTE_STATUSES = ['', 'To Do', 'In Progress', 'Done'];

export const NOTE_PRIORITIES = ['', 'Low', 'Medium', 'High'];

export const NOTE_TYPES = ['All', 'Finance', 'Legal', 'Sales', 'Marketing', 'Support'];

export const NOTE_TYPE_COLORS = {
  Finance: 'bg-green-600 text-white',
  Legal: 'bg-purple-600 text-white',
  Sales: 'bg-blue-600 text-white',
  Marketing: 'bg-orange-600 text-white',
  Support: 'bg-red-600 text-white',
  All: 'bg-gray-600 text-white'
};

export const NOTE_TYPE_ICONS = {
  All: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>`,
  Finance: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>`,
  Legal: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
  </svg>`,
  Sales: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
  </svg>`,
  Marketing: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
  </svg>`,
  Support: `<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>`
};

export const NOTE_VISIBILITY_OPTIONS = [
  { value: 'me', label: 'Me Only', icon: '👤' },
  { value: 'team', label: 'My Team', icon: '👥' },
  { value: 'organization', label: 'Organization', icon: '🌎' }
];

export interface NoteComponentProps {
  type: 'contact' | 'account' | 'opportunity';
  id: number;
}
