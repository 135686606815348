import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { THEME_CLASSES } from '../../constants/themeConstants';
import { useEventBus } from '../../context/EventBusContext';

function SuggestionActionsPanel() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const { publish } = useEventBus();

  const handleSend = () => {
    if (inputValue.trim()) {
      publish('chat:open', inputValue.trim());
      setInputValue('');
    }
  };

  const suggestionActions = [
    {
      icon: (
        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
      ),
      label: 'Create Account',
      path: '/accounts',
      color: 'blue-600'
    },
    {
      icon: (
        <svg className="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
        </svg>
      ),
      label: 'Create Lead',
      path: '/leads',
      color: 'green-600'
    },
    {
      icon: (
        <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      ),
      label: 'Contact Person',
      path: '/contacts',
      color: 'indigo-600'
    },
    {
      icon: (
        <svg className="w-6 h-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      ),
      label: 'Log Note',
      path: '/notes',
      color: 'yellow-600'
    },
    {
      icon: (
        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      ),
      label: 'Manage Pipeline',
      path: '/pipelines',
      color: 'purple-600'
    }
  ];

  return (
    <div className="bottom-0 left-0 right-0 py-4 bg-gradient-to-t from-gray-50 dark:from-gray-900 to-transparent">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="overflow-x-auto pb-2">
          <div className="flex space-x-4">
            {suggestionActions.map((action) => (
              <button
                key={action.label}
                className={`flex items-center space-x-2 p-4 rounded-xl ${THEME_CLASSES.input.background} border ${THEME_CLASSES.border} min-w-[200px] ${THEME_CLASSES.hover.background} transition-colors duration-200`}
                onClick={() => navigate(action.path)}
              >
                {action.icon}
                <span className={`${THEME_CLASSES.text.primary}`}>{action.label}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="flex space-x-2">
          <input
            type="text"
            placeholder="Type your question here..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={`flex-1 rounded-lg px-4 py-2 ${THEME_CLASSES.input.background} ${THEME_CLASSES.input.border} ${THEME_CLASSES.input.text} ${THEME_CLASSES.input.placeholder} border focus:outline-none focus:ring-2 focus:ring-blue-500`}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSend();
              }
            }}
          />
          <button
            onClick={handleSend}
            disabled={!inputValue.trim()}
            className={`px-4 py-2 rounded-lg ${THEME_CLASSES.button.primary} transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuggestionActionsPanel;
