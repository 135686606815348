import { Note } from '../models/Note';

export function getNotesSummaryPrompt(notes: Note[]): string {

  if (!notes || notes.length === 0) return 'No notes to summarize';

  const noteText = (note: Note) => {
    let txt = `- ${note.title}: ${note.content}\n`;
    if (note.status) {
      txt += `  Status: (${note.status})\n`;
    }
    if (note.startDate) {
      txt += `  Start Date: ${new Date(note.startDate).toLocaleDateString()}\n`;
    }
    if (note.dueDate) {
      txt += `  Due Date: ${new Date(note.dueDate).toLocaleDateString()}\n`;
    }
    return txt;
  }
  const prompt = `Summarize these notes below, 
  categorized by status in a logical order
  and suggest a to do list by dates and priority:\n
  ${notes.map(n => noteText(n)).join('\n\n')}
  
  DO NOT add any new information, just summarize the existing notes.`;
  return prompt;
}
