import { FC } from 'react';
import { Opportunity } from '../../models/Opportunity';
import { useTheme } from '../../context/ThemeContext';
import { THEME_CLASSES } from '../../constants/themeConstants';

interface OpportunityTableProps {
  opportunities: Opportunity[];
  setEditingOpportunity: (opportunity: Opportunity) => void;
}

interface TableColumn {
  key: keyof Opportunity;
  header: string;
  format?: (value: any) => string;
}

const TABLE_COLUMNS: TableColumn[] = [
  { key: 'title', header: 'Title' },
  { 
    key: 'value', 
    header: 'Value', 
    format: (value: number | null) => value ? `$${value.toLocaleString()}` : '$0' 
  },
  { key: 'markets', header: 'Markets' },
  { key: 'province', header: 'Province' },
  { key: 'salesType', header: 'Type' },
  { key: 'numberOfAgents', header: 'Agents' },
  { 
    key: 'probability', 
    header: 'Probability', 
    format: (value: number | null) => value ? `${value}%` : '0%' 
  },
  { key: 'status', header: 'Status' },
  { key: 'owners', header: 'Owners' },
  { 
    key: 'lastOutreach', 
    header: 'Last Outreach', 
    format: (date: Date | null) => date ? new Date(date).toLocaleDateString() : '-' 
  },
  { 
    key: 'nextOutreach', 
    header: 'Next Outreach', 
    format: (date: Date | null) => date ? new Date(date).toLocaleDateString() : '-' 
  },
  { 
    key: 'targetCloseDate', 
    header: 'Target Close', 
    format: (date: Date | null) => date ? new Date(date).toLocaleDateString() : '-' 
  }
];

const OpportunityTable: FC<OpportunityTableProps> = ({ opportunities, setEditingOpportunity }) => {
  const { isDark } = useTheme();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border dark:border-gray-700">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              {TABLE_COLUMNS.map((column) => (
                <th 
                  key={column.key}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
            {opportunities.map((opportunity) => (
              <tr 
                key={opportunity.id} 
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={() => setEditingOpportunity(opportunity)}
              >
                {TABLE_COLUMNS.map((column) => {
                  const value = opportunity[column.key as keyof Opportunity];
                  const formattedValue = column.format ? 
                    column.format(value) : 
                    (value !== null && value !== undefined ? value.toString() : '-');
                  
                  return (
                    <td 
                      key={column.key}
                      className="px-6 py-4 whitespace-nowrap text-xs text-gray-900 dark:text-white"
                    >
                      {formattedValue}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpportunityTable;
